import { action, computed, decorate, observable, runInAction } from 'mobx'
import { path } from 'ramda'
import { isValid, format, addHours, parseISO } from 'date-fns'
import { loadBusinessEvents } from 'services/selfCare'

class SelCareFinishCtrl {
  choice = null
  amount = null
  appointmentDate = null
  loading = false
  businessEvents = []

  loadData = claim => {
    this.choice = path(['orientationSelfCare', 'choice'], claim)
    this.amount = path(['orientationSelfCare', 'amount'], claim)
    this.appointmentDate = path(['orientationSelfCare', 'appointmentDate'], claim)
      ? parseISO(path(['orientationSelfCare', 'appointmentDate'], claim))
      : null
  }

  loadBusinessEvents = async wan => {
    this.loading = true
    try {
      const res = await loadBusinessEvents(wan)
      runInAction(() => {
        this.businessEvents = res.map(event => event.attributes)
        this.loading = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  get formatAppointmentDate() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'dd/MM/y')
  }

  get appointmentDateHourStart() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'HH:mm')
  }

  get appointmentDateHourEnd() {
    if (!isValid(this.appointmentDate)) return null

    return format(addHours(new Date(this.appointmentDate), 2), 'HH:mm')
  }
}

const DecoratedSelCareFinishCtrl = decorate(SelCareFinishCtrl, {
  choice: observable,
  amount: observable,
  appointmentDate: observable,
  loading: observable,
  businessEvents: observable,

  loadData: action,
  loadBusinessEvents: action,

  formatAppointmentDate: computed,
  appointmentDateHourStart: computed,
  appointmentDateHourEnd: computed,
})

export default new DecoratedSelCareFinishCtrl()
