import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { format } from 'date-fns'

import UnitDetail from 'pages/EasyEstimation/components/UnitDetail'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import { euro } from 'utils/currency'

const OverViewModal = ({
  showModal,
  setShowModal,
  SelfCareStore: { wan, RISK_TYPES, ROOMS_LIST },
  SelCareWhereCtrl: { dateOfLoss, riskType, damageRooms },
  CartStore: { rcp, furnitures, relatedCostPackages },
  SelCareEstimationCtrl: { isValid, loading, save },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const { t } = useTranslation()

  let riskTypeLabel = ''
  RISK_TYPES.forEach(r => {
    if (r.id === riskType) riskTypeLabel = r.value
  })
  return (
    <ModalWithBackdrop
      show={showModal}
      size="modal-lg"
      close={() => setShowModal(false)}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h4 className="modal-title">{t('selfCare.estimation.overView.title')}</h4>
        <button type="button" className="close" onClick={() => setShowModal(false)}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="row no-gutters">
          <div className="col-12 font-weight-bold">Localisation</div>
          <div className="col-12 pl-4">
            {t('selfCare.estimation.overView.dateOfLoss')} :{' '}
            {format(dateOfLoss, t('common.dateFormat'))} <br />
            {t('selfCare.estimation.overView.riskType')} : {riskTypeLabel} <br />
            {t('selfCare.estimation.overView.damage')} <br />
            <ul>
              {ROOMS_LIST[riskType].map(room => {
                if (damageRooms.includes(room.cde))
                  return <li key={`damage-room-${room.cde}`}>{room.selfCareLabel}</li>
                return null
              })}
            </ul>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 font-weight-bold">
            {t('selfCare.estimation.overView.estimation')}
          </div>
          <div className="col-12 pl-4">
            <div className="font-weight-bold"> {t('selfCare.estimation.overView.property')} </div>
            {rcp.length === 0 && (
              <div className="pl-4">{t('selfCare.estimation.overView.noPropertyDamage')}</div>
            )}
            {rcp.length > 0 &&
              rcp.map(rcpData => (
                <div className="pl-4" key={`rcp-${rcpData.id}`}>
                  <span className="font-weight-bold">{rcpData.roomselfCareLabel}</span>
                  <br />
                  <span className="font-weight-bold">
                    {t(`mission.easyEstimation.parts.${rcpData.part}`)}
                  </span>
                  <br />
                  <UnitDetail
                    part={rcpData.part}
                    unitByDefault={rcpData.unitByDefault}
                    area={rcpData.area}
                    quantity={rcpData.quantity}
                    percentageDammaged={rcpData.percentageDammaged}
                    label={rcpData.covering.label}
                  />
                </div>
              ))}

            <div className="font-weight-bold pt-2">
              {t('selfCare.estimation.overView.furniture')}
            </div>
            {furnitures.length === 0 && (
              <div className="pl-4">{t('selfCare.estimation.overView.noFurnitureDamage')}</div>
            )}
            {furnitures.length > 0 &&
              furnitures.map(furniture => (
                <div className="pl-4" key={`furniture-${furniture.id}`}>
                  {furniture.description} {euro(furniture.totalPriceVAT)}
                </div>
              ))}

            <div className="font-weight-bold pt-2">
              {t('selfCare.estimation.overView.relatedCost')}
            </div>
            {relatedCostPackages.length === 0 && (
              <div className="pl-4">{t('selfCare.estimation.overView.noRelatedCostDamage')}</div>
            )}
            {relatedCostPackages.length > 0 &&
              relatedCostPackages.map(relatedCost => (
                <div className="pl-4" key={`relatedCost-${relatedCost.id}`}>
                  {relatedCost.description} {euro(relatedCost.priceWithVAT)}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="modal-footer pb-2 pt-2 d-flex justify-content-between">
        <Button
          className="btn btn-secondary"
          onClick={() => setShowModal(false)}
          disabled={loading}
          loading={loading}
        >
          {t('common.dismiss')}
        </Button>

        <Button
          className="btn btn-success"
          onClick={() => save(wan)}
          disabled={!isValid || loading}
          loading={loading}
        >
          {t('selfCare.estimation.overView.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default inject('SelCareEstimationCtrl', 'SelfCareStore', 'SelCareWhereCtrl', 'CartStore')(
  observer(OverViewModal),
)
