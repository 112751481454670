import request from 'services/httpRequest'

export const fetchSelfCare = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/self-care/${wan}`,
    })

    return res.data.attributes
  } catch (error) {
    throw error
  }
}
export const getSelfCareForm = async () => {
  const { data } = await request({
    method: 'GET',
    url: '/self-care/create',
  })
  return data[0].attributes
}

export const createSelfCareClaim = async formData => {
  const data = {
    data: {
      type: 'selfCare',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/self-care/create`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const saveSelfCareForm = async (wan, attributes, form) => {
  const data = {
    data: {
      type: form,
      attributes: attributes,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/self-care/${wan}/static-form/${form}`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const backSelfCare = async (wan, form) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/self-care/${wan}/go-back/${form}`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const getOrientationSelfCare = async wan => {
  const res = await request({
    method: 'get',
    url: `/self-care/${wan}/orientation`,
  })

  return res.data.attributes
}

export const confirmOrientationSelfCare = async (wan, attributes) => {
  const data = {
    data: {
      type: 'selfCareSelectIdemnisation',
      attributes: attributes,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/self-care/${wan}/orientation`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const abandonSelfCare = async (wan, attributes) => {
  const data = {
    data: {
      type: 'selfCareAbandon',
      attributes: attributes,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/self-care/${wan}/abandon`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const pauseSelfCare = async (wan, waitChoice) => {
  try {
    await request({
      method: 'POST',
      url: `/self-care/${wan}/pause/${waitChoice}`,
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const loadBusinessEvents = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/self-care/${wan}/business-event`,
    })

    return res.data
  } catch (error) {
    throw error
  }
}
