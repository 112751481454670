import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { add, multiply } from 'ramda'

import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import {
  LabelVAT,
  DisabledLabelVAT,
} from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import Toggle from 'components/Toggle/Toggle'
import { euro } from 'utils/currency'
import { computeTotalItems, computeTotalItemsSelfRepair, getMultiplier } from 'utils/easyEstimation'
import useClickOutside from 'hooks/useClickOutside'

const _convertDuration = (value, round = false) => {
  const sec_num = parseInt(value, 10)
  const hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (seconds > 30 && round) minutes = minutes + 1
  if (seconds < 10 && !round) seconds = `0${seconds}`

  return `${hours}h${minutes}m${!round ? seconds : ''}`
}

const CoveringRecap = ({ isClosed, rcpData, rcpPackages, CartStore: { findDifficultyRate } }) => {
  const [showRecap, setShowRecap] = useState(false)

  let domNode = useClickOutside(() => {
    setShowRecap(false)
  })

  const { t } = useTranslation()
  if (rcpPackages.length === 0) return null

  const packageData = rcpPackages[0]
  const selfRepair = packageData.selfRepair

  // TIME CATALOG
  const totalTime = rcpPackages.reduce((acc, data) => {
    data.items.forEach(item => {
      if (item.piActive) acc = add(acc, multiply(item.qtUnitMinute, getMultiplier(data, rcpData)))
    })
    return acc
  }, 0)
  const catalogDuration = _convertDuration(totalTime * 60, true)

  // TIME SELF REPAIR
  const totalSelfRepairTime = rcpPackages.reduce((acc, data) => {
    data.items.forEach(item => {
      if (item.piActive)
        acc = add(
          acc,
          item.qtUnitMinute *
            findDifficultyRate(item.packageDifficulty) *
            getMultiplier(data, rcpData),
        )
    })
    return acc
  }, 0)
  const selfRepairDuration = _convertDuration(totalSelfRepairTime * 60, true)

  // TOTAL ITEMS
  const totalItems = computeTotalItems(rcpPackages, rcpData)
  const totalItemsSelfRepair = computeTotalItemsSelfRepair(rcpPackages, rcpData)

  // Cout main d'oeuvre
  const totalCatalog =
    rcpPackages.reduce((acc, data) => add(acc, data.finalPriceCatalogWithoutVAT), 0) - totalItems
  const totalSelfRepair =
    rcpPackages.reduce((acc, data) => add(acc, data.finalPriceSelfRepairWithoutVAT), 0) -
    totalItemsSelfRepair

  // AVERAGE HOUR PRICE
  const averageHourPrice = (totalCatalog / totalTime) * 60

  // Total à neuf HT
  const totalCatalogWithoutVAT = rcpPackages.reduce(
    (acc, data) => add(acc, data.finalPriceCatalogWithoutVAT),
    0,
  )
  const totalSelfRepairWithoutVAT = rcpPackages.reduce(
    (acc, data) => add(acc, data.finalPriceSelfRepairWithoutVAT),
    0,
  )

  // NORMAL COMPUTATION
  // const totalCovering = rcpPackages.reduce((acc, data) => add(acc, data.finalPrice), 0)

  // const totalCoveringSelfRepair = rcpPackages.reduce((acc, data) => add(acc, data.finalPriceSelfRepair), 0)
  const totalCoveringPriceCatalog = rcpPackages.reduce(
    (acc, data) => add(acc, data.finalPriceCatalog),
    0,
  )

  const totalVAT = rcpPackages.reduce((acc, data) => add(acc, data.totalVAT), 0)
  // const totalPriceVAT = rcpPackages.reduce((acc, data) => add(acc, data.totalPriceVAT), 0)

  const onChange = e => {
    rcpPackages.forEach(packageData => packageData.setProperty(e.target.name, e.target.value))
  }

  return (
    <div ref={domNode} className="covering-recap-wrapper">
      <button
        className={classNames('btn rounded-circle mr-1', {
          'btn-primary': showRecap,
          'btn-outline-primary': !showRecap,
        })}
        onClick={() => setShowRecap(!showRecap)}
      >
        <i
          className={classNames('fa', {
            'fa-search-minus': showRecap,
            'fa-search-plus': !showRecap,
          })}
        />
      </button>

      {showRecap && (
        <div className="covering-recap border p-3">
          <RecapRowLayout
            labelComponent={
              <div className="d-flex align-items-center">
                <span className="mr-2">REN :</span>
                <Toggle
                  name="ren"
                  onChange={onChange}
                  checked={packageData.ren}
                  disabled={packageData.selfRepair || isClosed}
                />
              </div>
            }
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">Prix entreprise</div>
                <div className="col-6 text-right">GAG</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Taux horaire</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{euro(averageHourPrice)}</div>
                <div className="col-6 text-right">-</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Temps MO</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{catalogDuration}</div>
                <div className="col-6 text-right">{selfRepairDuration}</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Coût MO</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{euro(totalCatalog)}</div>
                <div className="col-6 text-right">{euro(totalSelfRepair)}</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Coût fournitures</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{euro(totalItems)}</div>
                <div className="col-6 text-right">{euro(totalItemsSelfRepair)}</div>
              </div>
            }
            packageLayout={false}
          />

          <hr />

          {!selfRepair && (
            <RecapRowLayout
              labelComponent={
                <span className="recap-label mr-3">
                  {t('mission.calculation.detailPackage.recapTitle')}
                </span>
              }
              priceComponent={
                <div className="row">
                  <div className="col-6 text-right">{euro(totalCatalogWithoutVAT)}</div>
                  <div className="col-6 text-right"></div>
                </div>
              }
              packageLayout={false}
            />
          )}

          {selfRepair && (
            <RecapRowLayout
              labelComponent={
                <span className="recap-label mr-3">
                  {t('mission.calculation.detailPackage.totalWithVAT')}
                </span>
              }
              priceComponent={
                <div className="row">
                  <div className="col-6 text-right"></div>
                  <div className="col-6 text-right">{euro(totalSelfRepairWithoutVAT)}</div>
                </div>
              }
              packageLayout={false}
            />
          )}

          {!selfRepair && (
            <RecapRowLayout
              labelComponent={
                isClosed ? (
                  <DisabledLabelVAT packageData={packageData} />
                ) : (
                  <LabelVAT packageData={packageData} onChange={onChange} />
                )
              }
              packageLayout={false}
              priceComponent={
                <div className="row">
                  <div className="col-6 text-right">{euro(totalVAT)}</div>
                </div>
              }
            />
          )}

          {!selfRepair && (
            <RecapRowLayout
              labelComponent={
                <span className="recap-label">
                  {t('mission.calculation.detailPackage.totalWithVAT')}
                </span>
              }
              packageLayout={false}
              priceComponent={
                <div className="row">
                  <div className="col-6 text-right">{euro(totalCoveringPriceCatalog)}</div>
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  )
}

export default inject('CartStore')(observer(CoveringRecap))
