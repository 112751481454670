import React, { Fragment, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import LiteGallery from 'pages/Mission/components/LiteGallery'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import FiltersDropZone from 'pages/SupportingDocuments/FiltersDropZone'
import FiltersDropZoneUpdateType from 'pages/SupportingDocuments/FiltersDropZoneUpdateType'
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal'
import Filters from 'components/SDModal/Filters'
import List from 'components/SDModal/List'
import classNames from 'classnames'

const SupportingDocuments = ({
  MissionStore,
  ManagerClaimStore,
  UserStore,
  inputData: {
    showModal,
    setShowModal,
    value,
    removeDocument,
    selectDocument,
    selectingDocuments,
    confirmSelection,
  },
  t,
}) => {
  const [grabbing, setGrabbing] = useState(false)

  const wan = UserStore.isClaimManager ? ManagerClaimStore.id : MissionStore.id

  return (
    <Fragment>
      <div className="d-flex flex-column">
        <button className="btn btn-primary col-2" onClick={() => setShowModal(true)}>
          <i className="fa fa-upload" />
        </button>
        <LiteGallery
          supportingDocuments={value}
          forPackages={true}
          hasDeleteBtn={true}
          onDelete={removeDocument}
        />
      </div>
      <ModalWithBackdrop
        show={showModal}
        size="modal-xl"
        close={() => setShowModal(false)}
        draggable
        setGrabbing={setGrabbing}
        scrollable
      >
        <div
          className={classNames('modal-header d-flex justify-content-between', {
            grabbable: !grabbing,
            grabbing: grabbing,
          })}
        >
          <h5 className="modal-title">{t('messaging.form.sdTitle')}</h5>

          <i className="fa fa-close clickable" onClick={() => setShowModal(false)} />
        </div>

        <div className="modal-body supporting-documents container">
          <Filters />
          {!SupportingDocumentsCtrl.dropZoneFiltered &&
            !SupportingDocumentsCtrl.dropZoneFilteredUpdateType && (
              <List onSelectItem={selectDocument} selectedItems={selectingDocuments} />
            )}

          <FiltersDropZone />
          <FiltersDropZoneUpdateType />
          <UploadDocumentModal wan={wan} />
        </div>

        <div className="modal-footer pb-2 pt-2">
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            {t('common.cancel')}
          </button>
          <Button className="btn btn-success" onClick={() => confirmSelection()}>
            {t('common.select2')}
          </Button>
        </div>
      </ModalWithBackdrop>
    </Fragment>
  )
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore', 'UserStore'),
  withTranslation(),
  observer,
)(SupportingDocuments)
