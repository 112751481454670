import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import Select from 'components/Forms/Select'
import Label from 'components/Label/Label'
import ImgEvent from 'pages/EasyEstimation/components/ImgEvent'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import './selfcareWhat.css'
import { useTranslation } from 'react-i18next'
const SelfCareWhat = ({
  SelfCareStore: { wan, COVERAGES, COVERAGE_EVENTS },
  SelCareWhatCtrl: { setProperty, loading, coverageKey, eventTypeKey, isValid, save, back },
}) => {
  const [showModal, setShowModal] = useState(false)
  const [grabbing, setGrabbing] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(false)
  const { t } = useTranslation()

  const onClickHandler = event => {
    setSelectedEvent(event)
    setShowModal(true)
  }

  const onCloseHandler = () => {
    setShowModal(false)
    setSelectedEvent(false)
  }

  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="form-group col-8">
          <Label required={true}>{t('selfCare.what.event')}</Label>
          <Select
            className="form-control"
            name="coverageKey"
            options={COVERAGES}
            value={coverageKey}
            onChange={e => setProperty('coverageKey', e.target.value)}
            placeholder=" - Selection - "
          />
        </div>
      </div>

      {coverageKey && COVERAGE_EVENTS[coverageKey].length > 0 && (
        <div className="row no-gutters">
          <div className="form-group col-12 mt-4">
            <Label className="d-block">{t('selfCare.what.eventCause')}</Label>
            <div className="d-flex flex-row flex-wrap justify-content-between p-3">
              {COVERAGE_EVENTS[coverageKey].map(event => {
                return (
                  <div
                    className={classNames(
                      'img-box d-flex flex-column align-items-center border rounded mr-2 mb-2 text-center overflow-auto pt-3 clickable event-container',
                      {
                        'border-primary': eventTypeKey === event.id,
                        'text-primary': eventTypeKey === event.id,
                        'text-muted': eventTypeKey !== event.id,
                      },
                    )}
                  >
                    {event.selfCareDescription && (
                      <i
                        className="fa fa-info info-event"
                        onClick={() => onClickHandler(event)}
                      ></i>
                    )}
                    <div
                      key={`event-${event.id}`}
                      onClick={() => setProperty('eventTypeKey', event.id)}
                    >
                      <ImgEvent selected={eventTypeKey === event.id} imgId={event.id} />
                      <span>{event.selfCareLabel || event.value}</span>
                    </div>
                  </div>
                )
              })}
              <ModalWithBackdrop
                show={showModal}
                size="modal-lg"
                close={onCloseHandler}
                draggable
                setGrabbing={setGrabbing}
              >
                <div
                  className={classNames('modal-header', {
                    grabbable: !grabbing,
                    grabbing: grabbing,
                  })}
                >
                  <h5 className="modal-title">
                    {t('selfCare.what.modal.title')}{' '}
                    {selectedEvent.selfCareLabel || selectedEvent.value}
                  </h5>
                  <button type="button" className="close" onClick={onCloseHandler}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{selectedEvent.selfCareDescription}</p>
                </div>
              </ModalWithBackdrop>
            </div>
          </div>
        </div>
      )}

      <div className="row no-gutters mt-4 d-flex justify-content-between">
        <Button
          className="btn btn-secondary"
          onClick={() => back(wan)}
          disabled={loading}
          loading={loading}
        >
          {t('selfCare.common.back')}
        </Button>

        <Button
          className="btn btn-primary"
          onClick={() => save(wan)}
          disabled={!isValid || loading}
          loading={loading}
        >
          {t('selfCare.common.next')}
        </Button>
      </div>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareWhatCtrl')(observer(SelfCareWhat))
