import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import FurnitureShow from 'pages/SelfCare/Estimation/FurnitureShow'
import FurnitureAdd from 'pages/SelfCare/Estimation/FurnitureAdd'
import Button from 'components/Button/Button'

const Furnitures = ({
  CartStore: { furnitures, addFurniture, removeFurniture },
  FurnitureCtrl: { furniture, editionId, setNewFurniture, setProperty },
  SelCareEstimationCtrl: { loading },
}) => {
  const { t } = useTranslation()
  const [showAdd, setShowAdd] = useState(false)

  const addAction = () => {
    setNewFurniture()
    setShowAdd(true)
  }

  const onDelete = id => {
    removeFurniture(id)
    setProperty('editionId', null)
  }

  return (
    <div>
      {furnitures.map(f => (
        <Fragment key={`furniture-${f.id}`}>
          {f.id === editionId && (
            <FurnitureAdd
              key={`furniture-edit-${f.id}`}
              furniture={f}
              onCancel={() => setProperty('editionId', null)}
              onConfirm={() => setProperty('editionId', null)}
              mode="edit"
            />
          )}

          {f.id !== editionId && (
            <FurnitureShow
              key={`furniture-show-${f.id}`}
              furniture={f}
              onEdit={() => setProperty('editionId', f.id)}
              onDelete={() => onDelete(f.id)}
              loading={loading}
            />
          )}
        </Fragment>
      ))}

      {showAdd && (
        <FurnitureAdd
          furniture={furniture}
          onCancel={() => setShowAdd(false)}
          onConfirm={() => {
            addFurniture(furniture)
            setShowAdd(false)
          }}
          mode="new"
        />
      )}

      {!showAdd && (
        <div className="mb-3">
          <Button className="btn btn-primary" onClick={addAction} disabled={loading}>
            {t('selfCare.estimation.furniture.add')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default inject('CartStore', 'FurnitureCtrl', 'SelCareEstimationCtrl')(observer(Furnitures))
