import request from 'services/httpRequest'

export const fetchMissionDamageDescForm = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsDamageDescription`,
  })
  return data[0].attributes
}

export const fetchCausesAndCircumstancesForm = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsCausesAndCircumstances`,
  })
  return data[0].attributes
}

export const saveFormDamageDescription = async (wan, formData) => {
  const data = { data: { type: 'SpsDamageDescription', attributes: formData } }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsDamageDescription`,
      data,
    })
    return res
  } catch (err) {
    const errors = []
    err.data.errors.forEach(error => {
      errors.push({
        key: error.source.pointer.split('/').pop(),
        value: error.detail,
      })
    })
    throw errors
  }
}

export const saveFormDamagesDetails = async (wan, formData) => {
  const data = {
    data: { type: 'SpsCausesAndCircumstances', attributes: formData },
  }

  try {
    return await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsCausesAndCircumstances`,
      data,
    })
  } catch (err) {
    const errors = []
    if (err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}

export const fetchCreateMissionForm = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/${wan}/missions-insurer/form`,
  })
  return data[0].attributes
}

export const fetchSuggestedExperts = async (wan, formData) => {
  try {
    const data = {
      data: {
        type: 'SpsMissionInsurer',
        attributes: formData,
      },
    }

    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/${wan}/missions-insurer/search-suggested-experts`,
      data,
    })
    return res.data
  } catch (err) {
    return []
  }
}

export const fetchRiskConformity = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsRiskConformity`,
  })
  return data[0].attributes
}

export const saveRiskConformity = async (wan, formData) => {
  const data = {
    data: {
      type: 'SpsRiskConformity',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsRiskConformity`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const fetchConclusion = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsConclusion`,
  })
  return data[0].attributes
}

export const saveConclusion = async (wan, formData) => {
  try {
    return await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsConclusion`,
      data: {
        data: {
          type: 'SpsConclusion',
          attributes: formData,
        },
      },
    })
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}

export const fetchIrsi = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsIrsi`,
  })
  return data[0].attributes
}

export const saveIrsi = async (wan, formData) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsIrsi`,
      data: {
        data: {
          type: 'SpsIrsi',
          attributes: formData,
        },
      },
    })

    return res.data.attributes
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}
export const fetchPv = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsPv`,
  })
  return data[0].attributes
}

export const savePv = async (wan, formData, pvEstimation = []) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsPv`,
      data: {
        data: {
          type: 'SpsPv',
          attributes: {
            ...formData,
            pvEstimation,
          },
        },
      },
    })

    return res.data.attributes
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}

export const fetchWorkRequest = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsWorkRequest`,
  })
  return data[0].attributes
}

export const saveWorkRequest = async (wan, formData) => {
  const data = {
    data: {
      type: 'SpsWorkRequest',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsWorkRequest`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const fetchAgreementProtocol = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsAgreementProtocol`,
  })
  return data[0].attributes
}

export const saveAgreementProtocol = async (wan, formData) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/SpsAgreementProtocol`,
      data: {
        data: {
          type: 'SpsAgreementProtocol',
          attributes: formData,
        },
      },
    })

    return res.data.attributes
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}
