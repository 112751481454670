import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { splitEvery } from 'ramda'

import Button from 'components/Button/Button'
import CoveringImg from 'pages/EasyEstimation/PropertyEmbellishment/CoveringImg'
import CoveringUnit from 'pages/SelfCare/Estimation/CoveringUnit'
import PercentageDammaged from 'pages/SelfCare/Estimation/PercentageDammaged'
import UploadPhoto from 'pages/SelfCare/Estimation/UploadPhoto'
import { useTranslation } from 'react-i18next'
const CoveringForm = ({
  mode,
  show,
  closeForm,
  SelfCareStore: { wan },
  PropertyEmbellishmentCtrl: {
    setProperty,
    currentParts,
    selectedPart,
    selectedCovering,
    selectedCoveringCde,
    fetchRoomCovering,
    fetching,
    qualityType,
    items,
    changePackagesProperty,
    packages,
    isSquareMeter,
    totalCoveringPerUnity,
    addCovering,
    resetCoveringForm,
    photo1,
    photo2,
    currentRoom,
  },
}) => {
  useEffect(() => {
    if (currentParts.length === 1) {
      setProperty('selectedPart', currentParts[0].id)
    }
  }, [currentParts, setProperty])

  const coveringRows = splitEvery(4, selectedCovering)
  const { t } = useTranslation()
  const flexBasisCurrentPart = `btn flex-basis-1-${currentParts.length}`

  return (
    <div className="mb-3">
      {show && (
        <div className="border border-primary rounded p-3 mt-3 mb-3">
          <div>
            <label className="d-block">{t('selfCare.estimation.covering.damageSurface')}</label>

            <div className="btn-group pl-0 d-flex">
              {currentParts.map(part => (
                <button
                  key={`button-part-${part.id}`}
                  className={classNames(flexBasisCurrentPart, {
                    'btn-primary': selectedPart === part.id,
                    'btn-outline-primary': selectedPart !== part.id,
                  })}
                  onClick={() => {
                    setProperty('selectedPart', part.id)
                    setProperty('packages', [])
                  }}
                >
                  {part.label}
                </button>
              ))}
            </div>
          </div>

          {selectedCovering.length > 0 && (
            <div className="mt-3 mb-3">
              <label className="d-block">{t('selfCare.estimation.covering.prestation')}</label>
              <div className="d-flex flex-row flex-wrap">
                {coveringRows.map((row, i) =>
                  row.map(covering => (
                    <div
                      key={`covering-${i}-${covering.covering.cde}`}
                      className={classNames(
                        'covering-box d-flex flex-column align-items-center border rounded mr-2 mb-2 text-center overflow-auto pt-3',
                        {
                          clickable: !fetching,
                          'not-allowed': fetching || covering.covering.cde === selectedCoveringCde,
                          'border-primary': covering.covering.cde === selectedCoveringCde,
                          'text-primary': covering.covering.cde === selectedCoveringCde,
                          'text-muted': fetching && covering.covering.cde !== selectedCoveringCde,
                        },
                      )}
                      onClick={() => {
                        if (!fetching && covering.covering.cde !== selectedCoveringCde) {
                          fetchRoomCovering(wan, covering.covering.cde)
                        }
                      }}
                    >
                      <CoveringImg
                        selected={covering.covering.cde === selectedCoveringCde}
                        coveringId={covering.covering.cde}
                      />
                      <span>{covering.covering.label}</span>
                    </div>
                  )),
                )}
              </div>
            </div>
          )}

          <CoveringUnit />

          <PercentageDammaged />

          <div className="mt-3 mb-3">
            <label className="d-block">{t('selfCare.estimation.covering.photos')}</label>
            <div className="d-flex">
              <UploadPhoto
                photoId={photo1}
                setPhotoId={id => setProperty('photo1', id)}
                deletePhotoId={() => setProperty('photo1', null)}
                filename="Photo vue d'ensemble"
                filenameToUpload={`${currentRoom.label}-Photo vue d'ensemble`}
                wan={wan}
              />
              <UploadPhoto
                photoId={photo2}
                setPhotoId={id => setProperty('photo2', id)}
                deletePhotoId={() => setProperty('photo2', null)}
                filename="Photo du dommage"
                filenameToUpload={`${currentRoom.label}-Photo du dommage`}
                wan={wan}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            {show && (
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  resetCoveringForm()
                  closeForm()
                }}
              >
                {t('common.cancel')}
              </Button>
            )}
            {show && (
              <Button
                className="ml-2 btn btn-primary"
                onClick={() => {
                  addCovering(mode)
                  closeForm()
                }}
                disabled={packages.length === 0}
              >
                {t('common.validate')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default inject('SelfCareStore', 'PropertyEmbellishmentCtrl')(observer(CoveringForm))
