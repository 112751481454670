import { path, keys, isNil, type, mergeDeepRight } from 'ramda'
import jexl from 'jexl'

import InputString from './InputString'
import TextArea from './TextArea'
import Select from './Select'
import InputNumber from './InputNumber'
import InputPrice from './InputPrice'
import Address from './Address'
import Date from './Date'
import Table from './Table'
import InvolvedParty from './InvolvedPartyType'
import EmbeddedCollection from './EmbeddedCollection'
import Label from './Label'
import Signature from './Signature'
import SupportingDocuments from './SupportingDocuments'

import MissionStore from 'stores/Mission/domain/MissionStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import UserStore from 'stores/Common/domain/UserStore'

// INTERNALS
const _resetValue = (value, defaultValue, valuePath) => {
  if (isNil(defaultValue) && !isNil(valuePath)) {
    let cf = UserStore.isClaimManager ? ManagerClaimStore.claim : MissionStore.cfa
    defaultValue = path(valuePath.split('.'), cf)
  }

  if (!isNil(defaultValue)) return defaultValue

  if (isNil(value) || type(value) === 'Object') {
    return null
  } else if (type(value) === 'String') {
    return ''
  } else if (type(value) === 'Array') {
    return []
  }
  return null
}

const _choices = inputData => {
  const { value, choices, multiple, expanded, displayAsToggle = true } = inputData
  const choicesLength = keys(choices).length
  if (expanded && !multiple) return 'select'
  else if (choicesLength === 2 && !multiple && !expanded && displayAsToggle) {
    if (typeof value !== 'boolean') {
      inputData.value = typeof inputData.default === 'boolean' ? inputData.default : false
    }
    return 'toggle'
  } else if (choicesLength >= 3 && choicesLength <= 5 && !multiple) return 'select'
  else if (choicesLength > 5 && !multiple) return 'selectWithSearch'
  else if (multiple) return 'checkbox'

  return 'select'
}

const _verifyCondidtion = (condition, parent) => {
  let value = parent.value
  if (condition === true && value) return true
  else if (typeof condition === 'string' && condition && condition === value) return true
  else if (Array.isArray(condition) && condition.length && condition.includes(value)) return true
  else if (typeof condition === 'object') {
    value = condition.propertyOfInputParent ? parent[condition.propertyOfInputParent] : value
    switch (condition.operator) {
      case '===':
      case '==':
        return value === condition.operand
      case '!==':
      case '!=':
        return value !== condition.operand
      case '>':
        return value > condition.operand
      case '>=':
        return value >= condition.operand
      case '<':
        return value < condition.operand
      case '<=':
        return value <= condition.operand
      default:
        return false
    }
  }
  return false
}

const _runTreeHide = input => {
  if (input.children.length > 0) {
    input.children.forEach(child => {
      child.setProperty('show', false)
      child.setProperty('value', _resetValue(child.value, child.default, child.path))
      _runTreeHide(child)
    })
  }
}

export const arrayToTree = list => {
  const map = {}
  const roots = []
  let node = null

  for (let i = 0; i < list.length; i++) {
    map[list[i].name] = i
    list[i].children = []
  }
  for (let i = 0; i < list.length; i++) {
    node = list[i]
    if (node.parent) list[map[node.parent.field]].children.push(node)
    else roots.push(node)
  }
  return roots
}

export const runTree = tree => {
  tree.forEach(input => {
    if (input.children.length > 0) {
      input.children.forEach(child => {
        if (input.show && _verifyCondidtion(child.condition, input)) {
          child.setProperty('show', true)
          runTree(input.children)
        } else {
          child.setProperty('show', false)
          child.setProperty('value', _resetValue(child.value, child.default, child.path))
          _runTreeHide(child)
        }
      })
    }
  })
}
export const runEvaluation = (inputs, cf) => {
  inputs.forEach(input => {
    if (input.evaluationRule) {
      const evaluation = evaluationRule(input.evaluationRule, cf)
      input.setProperty('show', evaluation)
    }
  })
}

export const inputType = (inputData, validateRequired) => {
  switch (inputData.type) {
    case 'text':
      return new InputString(inputData)
    case 'tel':
      return new InputString(inputData)
    case 'signature':
      return new Signature(inputData)
    case 'supportingDocumentsType':
      return new SupportingDocuments(inputData)
    case 'number':
      return new InputNumber(inputData)
    case 'inputPrice':
      return new InputPrice(inputData)
    case 'textarea':
      return new TextArea(inputData)
    case 'choice':
    case 'entity':
      return new Select({ ...inputData, componentType: _choices(inputData) })
    case 'address':
      return new Address(inputData)
    case 'datetime':
      return new Date(inputData)
    case 'table':
      return new Table(inputData)
    case 'involvedPartySelectType':
      return new InvolvedParty(inputData)
    case 'embeddedCollection':
      return new EmbeddedCollection(inputData, validateRequired)
    case 'label':
      return new Label(inputData)
    default:
      console.log('input type unknow for dynform : ', inputData, inputData.type)
      return null
  }
}

export const formatEntityPath = path => {
  const pathArray = path.split('.')
  const lastKey = pathArray.pop()
  const newLastKey = lastKey.replace(/Key/, '')
  pathArray.push(newLastKey)
  pathArray.push('key')
  return pathArray.join('.')
}

export const shouldShow = (inputs, input) => {
  const parent = inputs.find(inputParent => inputParent.name === input.parent.field)
  if (!parent) return true
  else if (parent.show === false) return false
  return _verifyCondidtion(input.parent.notEmpty || input.parent.condition, parent)
}

export const evaluationRule = (rule, mission) => {
  const test = jexl.evalSync(rule, mission)
  return test
}

// SEARCH DATA FOR ENTITY
export const dataForEntity = (inputData, data, form) => {
  const formattedPath = formatEntityPath(inputData.path)
  const value = path(formattedPath.split('.'), data)

  inputData.value = value === undefined ? null : value

  if (isNil(inputData.linked)) return

  try {
    const linkedTo = form.fields.find(field => field.name === inputData.linked.field)

    if (isNil(linkedTo)) throw new Error('no link found')

    inputData.allChoices = inputData.choices

    if (isNil(linkedTo.value)) {
      inputData.choices = []
    } else if (inputData.choices[linkedTo.value]) {
      inputData.choices = inputData.choices[linkedTo.value].reduce((choices, cur) => {
        if (cur.key === inputData.value) inputData.value = cur.id
        choices[cur.value] = cur.id
        return choices
      }, {})
    } else throw new Error('no link found')
  } catch (error) {
    console.warn(`WARNING no link list found in form for ${inputData.name}`)
  }
}

// SEARCH DATA FOR VALUE
export const dataForValue = (inputData, data) => {
  if (isNil(data)) return

  let value = null
  if (inputData.type !== 'table') {
    value = path(inputData.path.split('.'), data)
  }

  if (value === undefined) {
    console.warn(`WARNING input path undefined in dynform ${inputData.name}: '${inputData.path}'`)
  }

  inputData.value = value === undefined ? null : value
}

// SEARCH DATA FOR EMBEDDED COLLECTION
export const dataForEmbeddedCollection = (inputData, data) => {
  if (isNil(data)) return
  // NEW REF OBJECT
  const newInputData = mergeDeepRight({}, inputData)

  const collectionData = path(newInputData.path.split('.'), data)

  if (isNil(collectionData) || type(collectionData) !== 'Array') {
    newInputData.collectionData = null
  } else {
    newInputData.collectionData = collectionData
  }
  return newInputData
}

// SEARCH DATA FOR EMBEDDED COLLECTION VALUE
export const dataForEmbeddedCollectionValue = (inputData, data) => {
  if (isNil(data)) return

  try {
    const key = inputData.path
      .split('.')
      .pop()
      .replace('Key', '')
    if (inputData.type === 'entity') {
      inputData.value = !isNil(data[key]) ? data[key].key : null
    } else {
      inputData.value = data[key]
    }
  } catch (error) {
    console.warn(error)
    inputData.value = null
  }
}
