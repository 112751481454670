import React, { Fragment, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { setHours, setMinutes, getDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { euro } from 'utils/currency'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import DatePicker from 'components/Forms/DatePicker'
const SelfCareIndemnisation = ({
  SelfCareStore: { wan },
  SelCareIndemnisationCtrl: {
    loading,
    setProperty,
    save,
    choice,
    isValid,
    appointmentDate,
    formatAppointmentDate,
    appointmentDateHourStart,
    appointmentDateHourEnd,
    accepedChoice,
    gagAvailable,
    gagAmount,
    mobilierAvailable,
    mobilierAmount,
    renAvailable,
    ipAvailable,
    gagMobilierAvailable,
    gagMobilierAmount,
    gagMobilierTravauxAmount,
    gagMobilierMobilierAmount,
    renMobilierAvailable,
    renMobilierTravauxAmount,
    renMobilierMobilierAmount,
    contactInsuredAvailable,
    showModal,
    backToIndemnisation,
    confirmOrientation,
    nbTotalChoices,
    positionOfChoice,
  },
}) => {
  // Trick to disable mobile keyboard on datepicker
  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container')
    Array.from(datePickers).forEach(el => el.childNodes[0].setAttribute('readOnly', true))
  })

  const { t } = useTranslation()

  const [grabbing, setGrabbing] = useState(false)

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(9)
  tomorrow.setMinutes(0)

  const minTime = setMinutes(setHours(new Date(), 9), 0)
  const maxTime = setMinutes(setHours(new Date(), 17), 0)

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  const closeModal = () => {
    backToIndemnisation(wan)
  }

  return (
    <Fragment>
      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={closeModal}
        draggable
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="modal-title">Confirmation</h5>
          <Button className="close" disabled={loading} loading={loading} onClick={closeModal}>
            <span>&times;</span>
          </Button>
        </div>

        <div className="modal-body">
          <div className="row no-gutters">
            <div className="col-12">{t('selfCare.indemnisation.confirmModal.title')}</div>
          </div>

          <div className="row no-gutters pl-4">
            <div className="col-12 font-weight-bold">
              {choice === 'gag' && t('selfCare.indemnisation.confirmModal.gag')}
              {choice === 'mobilier' && t('selfCare.indemnisation.confirmModal.mobilier')}
              {choice === 'ren' && t('selfCare.indemnisation.confirmModal.ren')}
              {choice === 'ip' && t('selfCare.indemnisation.confirmModal.ip')}
              {choice === 'gagMobilier' && t('selfCare.indemnisation.confirmModal.gagMobilier')}
              {choice === 'renMobilier' && t('selfCare.indemnisation.confirmModal.renMobilier')}
              {choice === 'contactInsured' &&
                t('selfCare.indemnisation.confirmModal.contactInsured')}
            </div>
          </div>

          <div className="row no-gutters mt-4 mb-4">
            <div className="col-12">
              <div className="form-group form-check">
                <input
                  id="accepedChoice"
                  className="form-check-input"
                  type="checkbox"
                  checked={accepedChoice}
                  onChange={() => setProperty('accepedChoice', !accepedChoice)}
                />
                <label htmlFor="accepedChoice" className="form-check-label">
                  {choice === 'gag' &&
                    t('selfCare.indemnisation.confirmModal.gagConfirm', {
                      gagAmount: euro(gagAmount),
                    })}

                  {choice === 'gagMobilier' &&
                    t('selfCare.indemnisation.confirmModal.gagMobilierConfirm', {
                      gagMobilierTravauxAmount: euro(gagMobilierTravauxAmount),
                      gagMobilierMobilierAmount: euro(gagMobilierMobilierAmount),
                    })}

                  {choice === 'mobilier' &&
                    t('selfCare.indemnisation.confirmModal.mobilierConfirm', {
                      mobilierAmount: euro(mobilierAmount),
                    })}

                  {choice === 'ren' &&
                    t('selfCare.indemnisation.confirmModal.renConfirm', {
                      formatAppointmentDate,
                      appointmentDateHourStart,
                      appointmentDateHourEnd,
                    })}

                  {choice === 'renMobilier' &&
                    t('selfCare.indemnisation.confirmModal.renMobilierConfirm', {
                      formatAppointmentDate,
                      appointmentDateHourStart,
                      appointmentDateHourEnd,
                      renMobilierMobilierAmount: euro(renMobilierMobilierAmount),
                    })}

                  {choice === 'ip' &&
                    t('selfCare.indemnisation.confirmModal.ipConfirm', {
                      formatAppointmentDate,
                      appointmentDateHourStart,
                      appointmentDateHourEnd,
                    })}

                  {choice === 'contactInsured' &&
                    t('selfCare.indemnisation.confirmModal.contactInsuredConfirm', {
                      formatAppointmentDate,
                      appointmentDateHourStart,
                      appointmentDateHourEnd,
                    })}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer pb-2 pt-2 d-flex justify-content-between">
          <Button
            className="btn btn-secondary"
            onClick={closeModal}
            disabled={loading}
            loading={loading}
          >
            Retour
          </Button>

          <Button
            className="btn btn-success"
            onClick={() => confirmOrientation(wan)}
            disabled={!isValid || loading}
            loading={loading}
          >
            Je valide ma déclaration
          </Button>
        </div>
      </ModalWithBackdrop>

      <div className="row no-gutters mb-4">
        <div className="col-12">
          <h4>{t('selfCare.indemnisation.choice', { nbTotalChoices })}</h4>
        </div>
      </div>

      {gagAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('gagAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.gag.title')}{' '}
              </h6>
              <br />
              <span>{t('selfCare.indemnisation.gag.title2')} </span>
              <br />
              <span>{t('selfCare.indemnisation.gag.amount')} </span>
              <span className="font-weight-bold">{euro(gagAmount)}</span>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setProperty('choice', 'gag')
                    save(wan)
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  {t('selfCare.indemnisation.confirmChoice')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('mobilierAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.mobilier.title')}
              </h6>
              <br />
              <span>{t('selfCare.indemnisation.mobilier.title2')}</span>
              <br />
              <span>{t('selfCare.indemnisation.mobilier.amount')}</span>
              <span className="font-weight-bold">{euro(mobilierAmount)}</span>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setProperty('choice', 'mobilier')
                    save(wan)
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  {t('selfCare.indemnisation.confirmChoice')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {renAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('renAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.ren.title')}
              </h6>
              <br />
              <span>{t('selfCare.indemnisation.ren.title2')}</span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="form-group col-12">
              <Label required={true}>{t('selfCare.indemnisation.ren.appointment')}</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'ren')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              {t('selfCare.indemnisation.confirmContact')}
            </Button>
          </div>
        </div>
      )}

      {ipAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('ipAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.ip.title')}
              </h6>
              <br />
            </div>
          </div>
          <div className="row no-gutters mt-2">
            <div className="form-group col-12">
              <Label required={true}>{t('selfCare.indemnisation.ip.appointment')}</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'ip')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              {t('selfCare.indemnisation.confirmContact')}
            </Button>
          </div>
        </div>
      )}

      {gagMobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('gagMobilierAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.gagMobilier.title')}
              </h6>
              <br />
              <span>{t('selfCare.indemnisation.gagMobilier.title2')}</span>
              <br />
              <span>{t('selfCare.indemnisation.gagMobilier.amount')}</span>
              <span className="font-weight-bold">{euro(gagMobilierAmount)}</span>

              <div className="mt-2">
                <span>{t('selfCare.indemnisation.gagMobilier.amountTravaux')}</span>{' '}
                <span className="font-weight-bold"> {euro(gagMobilierTravauxAmount)}</span>
                <br />
                <span>{t('selfCare.indemnisation.gagMobilier.amountFurniture')}</span>{' '}
                <span className="font-weight-bold"> {euro(gagMobilierMobilierAmount)}</span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'gagMobilier')
                save(wan)
              }}
              disabled={loading}
              loading={loading}
            >
              {t('selfCare.indemnisation.confirmChoice')}
            </Button>
          </div>
        </div>
      )}

      {renMobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('renMobilierAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.renMobilier.title')}
              </h6>
              <br />
              <span>{t('selfCare.indemnisation.renMobilier.title2')}</span>

              <div className="mt-2">
                <span>{t('selfCare.indemnisation.renMobilier.amountFurniture')}</span>{' '}
                <span className="font-weight-bold"> {euro(renMobilierMobilierAmount)}</span>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="form-group col-12">
              <Label required={true}>{t('selfCare.indemnisation.renMobilier.appointment')}</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'renMobilier')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              {t('selfCare.indemnisation.confirmContact')}
            </Button>
          </div>
        </div>
      )}

      {contactInsuredAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>
                {t('selfCare.indemnisation.choiceNumber', {
                  number: positionOfChoice('contactInsuredAvailable'),
                })}{' '}
                {t('selfCare.indemnisation.contactInsured.title')}
              </h6>
              <br />
            </div>
          </div>
          <div className="">
            <div className="">
              <Label required={true}>
                {t('selfCare.indemnisation.contactInsured.appointment')}
              </Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                datepickerPlacement="top"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'contactInsured')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              {t('selfCare.indemnisation.confirmContact')}
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareIndemnisationCtrl')(observer(SelfCareIndemnisation))
