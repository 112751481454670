import React, { Fragment, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { setHours, setMinutes, getDay } from 'date-fns'

import Label from 'components/Label/Label'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import DatePicker from 'components/Forms/DatePicker'

const SelfCareWaitAndAbandon = ({
  SelfCareStore: { wan, selfCareStatus },
  SelCareWaitAbandonCtrl: {
    loading,
    pause,
    isValid,
    abandon,
    appointmentDate,
    setProperty,
    waitChoices,
    waitChoice,
    isValidWait,
  },
}) => {
  const { t } = useTranslation()
  const [grabbing, setGrabbing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalWait, setShowModalWait] = useState(false)

  const onAbandon = async () => {
    await abandon(wan)
    setShowModal(false)
  }

  const onPause = async () => {
    if (waitChoice === 'abandon') {
      setShowModalWait(false)
      setShowModal(true)
      return
    }

    await pause(wan)
    setShowModalWait(false)
  }

  // Trick to disable mobile keyboard on datepicker
  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container')
    Array.from(datePickers).forEach(el => el.childNodes[0].setAttribute('readOnly', true))
  })

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(9)
  tomorrow.setMinutes(0)

  const minTime = setMinutes(setHours(new Date(), 9), 0)
  const maxTime = setMinutes(setHours(new Date(), 17), 0)

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  if (['finish', 'abandon'].includes(selfCareStatus)) return null

  return (
    <Fragment>
      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={() => setShowModal(false)}
        draggable
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="modal-title">{t('selfCare.abandon.title')}</h5>
          <button type="button" className="close" onClick={() => setShowModal(false)}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="row no-gutters">
            <div className="col-12">{t('selfCare.abandon.contactTitle')}</div>
          </div>

          <div className="row no-gutters mt-2">
            <div className="form-group col-12">
              <Label required={true}>{t('selfCare.abandon.contactTitle2')}</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer pb-2 pt-2 mt-2 d-flex justify-content-between">
          <Button
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
            disabled={loading}
            loading={loading}
          >
            {t('common.dismiss')}
          </Button>

          <Button
            className="btn btn-danger"
            onClick={onAbandon}
            disabled={!isValid || loading}
            loading={loading}
          >
            {t('common.confirm')}
          </Button>
        </div>
      </ModalWithBackdrop>

      <ModalWithBackdrop
        show={showModalWait}
        size="modal-lg"
        close={() => setShowModalWait(false)}
        draggable
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="modal-title">{t('selfCare.pause.title')}</h5>
          <button type="button" className="close" onClick={() => setShowModalWait(false)}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {waitChoices.map((choice, key) => (
            <div key={key} className="row no-gutters">
              <div className="col-12">
                <div className="form-group form-check">
                  <input
                    id={`waitChoice-${choice}`}
                    className="form-check-input"
                    type="checkbox"
                    checked={waitChoice === choice}
                    onChange={() => setProperty('waitChoice', choice)}
                  />
                  <label htmlFor={`waitChoice-${choice}`} className="form-check-label">
                    {t(`selfCare.pause.${choice}`)}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="modal-footer pb-2 pt-2 mt-2 d-flex justify-content-between">
          <Button
            className="btn btn-secondary"
            onClick={() => setShowModalWait(false)}
            disabled={loading}
            loading={loading}
          >
            {t('common.dismiss')}
          </Button>

          <Button
            className="btn btn-warning"
            onClick={onPause}
            disabled={!isValidWait || loading}
            loading={loading}
          >
            {t('common.confirm')}
          </Button>
        </div>
      </ModalWithBackdrop>

      <div className="row no-gutters mt-4 pt-4 d-flex justify-content-between">
        <Button
          className="btn btn-danger"
          onClick={() => setShowModal(true)}
          disabled={loading}
          loading={loading}
        >
          <i className="fa fa-sign-out" /> {t('selfCare.abandon.button')}
        </Button>

        <Button
          className="btn btn-primary"
          onClick={() => setShowModalWait(true)}
          disabled={loading}
          loading={loading}
        >
          <i className="fa fa-sign-out" /> {t('selfCare.pause.button')}
        </Button>
      </div>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareWaitAbandonCtrl')(observer(SelfCareWaitAndAbandon))
