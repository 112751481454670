import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'
import InputPrice from 'components/InputPrice/InputPrice'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import { path } from 'ramda'

const RelatedCostAdd = ({ relatedCost, onCancel, onConfirm, mode = 'new' }) => {
  const { t } = useTranslation()

  return (
    <div className="border border-primary rounded p-3 mb-3">
      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required>{t('mission.calculation.relatedCost.description')}</Label>
          <Input
            name="description"
            className="form-control"
            value={relatedCost.description}
            required={true}
            onChange={e => relatedCost.setProperty('description', e.target.value)}
          />
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required>{t('mission.calculation.relatedCost.totalWithVAT')}</Label>
          <InputPrice
            className="form-control"
            name="priceWithVAT"
            price={path(['priceWithVAT'], relatedCost) || 0}
            classNamesForPrice="font-weight-bold text-primary form-control"
            disableShowUnit
            maxLengthNumber={6}
            required={true}
            autoComplete="off"
            onChange={e => relatedCost.setProperty('priceWithVAT', e.target.value)}
          />
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12 d-flex justify-content-end">
          <Button className="btn btn-secondary" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            className="ml-2 btn btn-primary"
            onClick={onConfirm}
            disabled={!relatedCost.validRelatedCostEE}
          >
            {t('common.validate')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default inject('CommonStore')(observer(RelatedCostAdd))
