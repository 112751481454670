import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'

import Tooltip from 'components/Tooltip/Tooltip'
import { truncate } from 'utils'

const IncomingMessage = ({
  UserStore: { isExpert, isManager, quickSwitchMode },
  UiCtrl: { isMobile },
  insurerClaimId,
  insuredName,
  assigneeUserName,
  sender,
  text,
  sendAt,
  wanDest,
  attachment,
  wanType,
  isUrgent,
  isUrgentMission,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <tr
      className="clickable"
      onClick={() => {
        if (wanType === 'CFI' && isExpert) {
          quickSwitchMode('insurer')
        } else if (wanType === 'CFA' && isManager) {
          quickSwitchMode('expert')
        }
        history.push(
          `/${
            wanType === 'CFA' ? 'mission' : wanType === 'CFM' ? 'managerClaim' : 'claim'
          }/${wanDest}/messaging`,
        )
      }}
    >
      <td>
        {isUrgentMission && (
          <Tooltip className="d-inline" text={t('messaging.inbox.isUrgentMission')}>
            <i className="fa fa-warning text-primary" />
          </Tooltip>
        )}{' '}
        {insurerClaimId}
      </td>
      {!isMobile && <td>{insuredName}</td>}
      {!isMobile && <td>{assigneeUserName}</td>}
      {!isMobile && (
        <td>
          {sender}{' '}
          {isUrgent && (
            <Tooltip className="d-inline" text={t('messaging.urgentTooltip')}>
              <i className="fa fa-warning text-primary" />
            </Tooltip>
          )}
        </td>
      )}
      {!isMobile && <td>{format(sendAt, t('common.datetimeFormat'))}</td>}
      {!isMobile && <td>{wanDest}</td>}
      <td>
        <Tooltip className="tooltip-action-bar" text={text}>
          {truncate(text)}
        </Tooltip>
      </td>
      {!isMobile && <td>{attachment || null}</td>}
    </tr>
  )
}

export default inject('UserStore', 'UiCtrl')(observer(IncomingMessage))
