import { observable, action, computed, toJS, decorate } from 'mobx'
import { findIndex, propEq, values } from 'ramda'
import moment from 'moment'

import { isRequiredValid, isDateBeforeToday } from 'utils/supportingDocumentsForm'
import { updateSupportingDocuments, deleteSupportingDocuments } from 'services/supportingDocuments'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import CommonStore from 'stores/Common/domain/CommonStore'
import UserStore from 'stores/Common/domain/UserStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import SDExpertCtrl from 'stores/Common/view/SDExpertCtrl'
import SDInsurerCtrl from 'stores/Common/view/SDInsurerCtrl'
import SDClaimManagerCtrl from 'stores/Common/view/SDClaimManagerCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import MissionStore from 'stores/Mission/domain/MissionStore'

export const SupportingDocTypes = Object.freeze({
  PHOTO: 'CPHO',
  DOCUMENT: 'CDOC',
  SCHEMA: 'CSCH',
  INVOICE: 'CINV',
})

export const SupportingDocFileTypes = Object.freeze({
  PHOTO: 'image/*,application/pdf',
  DOCUMENT:
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/rtf,image/*',
  SCHEMA: 'image/*',
  INVOICE:
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/rtf,image/*',
})

export const SupportingDocMimeTypes = Object.freeze({
  MSWORD: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  ],
  MSEXCEL: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  MSPOWER: ['application/vnd.ms-powerpoint'],
  PDF: ['application/pdf'],
})

export const isImage = mimeType => {
  const regex = /image/
  return regex.test(mimeType.toLowerCase())
}

export const isMSWordMimeType = mimeType => {
  return SupportingDocMimeTypes.MSWORD.indexOf(mimeType) !== -1
}

export const isMSExcelMimeType = mimeType => {
  return SupportingDocMimeTypes.MSEXCEL.indexOf(mimeType) !== -1
}

export const isMSPowerMimeType = mimeType => {
  return SupportingDocMimeTypes.MSPOWER.indexOf(mimeType) !== -1
}

export const isPDFMimeType = mimeType => {
  return SupportingDocMimeTypes.PDF.indexOf(mimeType) !== -1
}

export const ReportSD = Object.freeze({
  ADD: 'addSDToReport',
  REMOVE: 'removeSDToReport',
})

class SupportingDocumentsCtrl {
  loading = true
  imageLoaded = {}
  filter = SupportingDocTypes.PHOTO
  modalDisplay = false
  sdToUpdate = null
  hasChanged = false
  updateLoading = false
  sdSelectLoading = false
  sdSelected = []
  sdAddedToReport = null
  modalDeleteDisplay = false
  selectedSlide = 0
  sliderModalDisplay = false
  dropZoneFiltered = false
  dropZoneFilteredUpdateType = false
  sdDragged = null
  invoiceError = {}
  createMissionSelected = []
  isCreateMissionSelector = false

  ctrl = null

  get notDeletableSelectedSd() {
    return this.sdSelected.filter(sd => {
      const index = findIndex(propEq('id', sd))(SupportingDocumentStore.supportingDocuments)
      return !SupportingDocumentStore.supportingDocuments[index]['canDelete']
    })
  }

  get selectedSd() {
    const selected = []
    this.sdSelected.forEach(sd => (sd !== undefined ? selected.push(sd) : null))
    return selected
  }

  get createMissionSelectedSd() {
    const selected = []
    this.createMissionSelected.forEach(sd => (sd !== undefined ? selected.push(sd) : null))
    return selected
  }

  get deletableSelectedSd() {
    return this.sdSelected.filter(sd => {
      const index = findIndex(propEq('id', sd))(SupportingDocumentStore.supportingDocuments)
      return SupportingDocumentStore.supportingDocuments[index]['canDelete']
    })
  }

  get sdCategory() {
    const mainType = values(SupportingDocTypes)
    const index = mainType.indexOf(this.filter)
    // case empty sd
    if (index !== -1) return mainType[index]

    return (
      !!SupportingDocumentStore.sdFiltered.length &&
      SupportingDocumentStore.sdFiltered[0]['type']['category']
    )
  }

  get sdCategoryLabel() {
    switch (this.sdCategory) {
      case SupportingDocTypes.PHOTO:
        return 'mission.supportingDocuments.photos'
      case SupportingDocTypes.DOCUMENT:
        return 'mission.supportingDocuments.documents'
      case SupportingDocTypes.INVOICE:
        return 'mission.supportingDocuments.invoices'
      default:
        return 'mission.supportingDocuments.schemas'
    }
  }

  get sdCategoryLabelLowerCase() {
    switch (this.sdCategory) {
      case SupportingDocTypes.PHOTO:
        return 'mission.upload.photos'
      case SupportingDocTypes.DOCUMENT:
        return 'mission.upload.documents'
      case SupportingDocTypes.INVOICE:
        return 'mission.upload.invoices'
      default:
        return 'mission.upload.schemas'
    }
  }

  setDefaultCtrl() {
    this.ctrl = UserStore.isExpert
      ? SDExpertCtrl
      : UserStore.isClaimManager
      ? SDClaimManagerCtrl
      : SDInsurerCtrl
  }

  fetchSupportingDocuments(wan) {
    this.ctrl
      .fetchSD(wan)
      .then(
        action(() => {
          this.loading = false
        }),
      )
      .catch(err => {
        AlertCtrl.alert('danger', `${err}`)
      })
  }

  setImagesLoaded(id) {
    this.imageLoaded = { ...this.imageLoaded, [id]: true }
  }

  setFilter(filter) {
    this.filter = filter
  }

  resetDatas() {
    this.imageLoaded = {}
  }

  // Can't manipulate the store directly in case of closing modal without saving

  setName(value, id) {
    const index = findIndex(propEq('id', id))(this.sdToUpdate)
    this.sdToUpdate[index]['name'] = value
    if (!this.hasChanged) this.hasChanged = true
  }

  setTypeAndName(value, id) {
    const index = findIndex(propEq('id', id))(this.sdToUpdate)
    this.sdToUpdate[index]['type'] = {
      ...this.sdToUpdate[index]['type'],
      key: value,
      name: CommonStore.supportingDocumentsTypes[this.sdCategory][value],
    }
    if (!this.hasChanged) this.hasChanged = true
  }

  updateSupportingDocument(id, extraData = null) {
    this.updateLoading = true
    const index = findIndex(propEq('id', id))(toJS(this.sdToUpdate))
    updateSupportingDocuments(
      id,
      this.sdToUpdate[index]['name'],
      this.sdToUpdate[index]['type']['key'],
      extraData ? extraData : this.sdToUpdate[index]['extraData'],
    )
      .then(
        action(res => {
          AlertCtrl.alert('success', 'mission.supportingDocuments.update.success')
          SupportingDocumentStore.supportingDocuments[index] = toJS(this.sdToUpdate[index])
          this.setFilter(this.sdToUpdate[index]['type']['category'])
          // In case of updating type by drag and drop
          if (this.dropZoneFilteredUpdateType) {
            this.setDropZoneFilteredUpdateType(false)
          } else {
            // In case of updating through the slider
            this.setSliderModalDisplay(false)
          }
          this.updateLoading = false
        }),
      )
      .catch(
        action(err => {
          AlertCtrl.alert('danger', `${err}`)
          this.updateLoading = false
        }),
      )
  }

  setDeleteModal() {
    this.modalDeleteDisplay = true
  }

  removeDeleteModal() {
    this.sdSelected = []
    this.modalDeleteDisplay = false
  }

  deleteSupportingDocument(wan) {
    // Array of Promise =
    const arrayOfPromises = this.deletableSelectedSd.map(id => {
      return deleteSupportingDocuments(UserStore.isInsurer, wan, id)
    })
    this.loading = true
    Promise.all(arrayOfPromises)
      .then(
        action(() => {
          // TO DO i18n issue in mobx controller, interpolate as string and not as translation
          if (UserStore.isExpert) {
            CartStore.fetch(wan, MissionStore.cfa)
          }
          this.deletableSelectedSd.length === 1
            ? AlertCtrl.alert('success', 'mission.supportingDocuments.deleteSuccess')
            : AlertCtrl.alert('success', 'mission.supportingDocuments.deleteSuccess_plural')
          this.deletableSelectedSd.forEach((id, index) => {
            const ind = findIndex(propEq('id', id))(SupportingDocumentStore.supportingDocuments)
            SupportingDocumentStore.supportingDocuments.splice(ind, 1)
          })
          this.modalDeleteDisplay = false
          this.sdSelected = []
          this.loading = false
        }),
      )
      .catch(
        action(err => {
          AlertCtrl.alert('danger', `${err}`)
          this.loading = false
        }),
      )
  }

  addSelectedSd(sdId) {
    this.sdSelected.push(sdId)
  }

  addSelectedSdAll() {
    SupportingDocumentStore.sdFiltered.forEach(sd => {
      this.addSelectedSd(sd.id)
    })
  }

  removeSelectedSd(sdId) {
    const index = this.sdSelected.indexOf(sdId)
    if (index !== -1) {
      this.sdSelected.splice(index, 1)
    }
  }

  setSelectedSlide(item) {
    this.selectedSlide = item
    SupportingDocumentStore.supportingDocuments[item].showLocationPhoto = false
  }

  setSliderModalDisplay(open) {
    this.sdToUpdate = toJS(SupportingDocumentStore.supportingDocuments)
    this.sliderModalDisplay = open
    if (!open) this.invoiceError = {}
  }

  setDropZoneFiltered(open) {
    this.dropZoneFiltered = open
  }

  resetSdSelected() {
    this.sdSelected = []
  }

  setDropZoneFilteredUpdateType(open, sdDragged) {
    if (sdDragged) {
      this.setSdDragged(sdDragged)
      this.sdToUpdate = toJS(SupportingDocumentStore.supportingDocuments)
    } else {
      this.sdToUpdate = null
    }
    this.dropZoneFilteredUpdateType = open
  }

  setSdDragged(sdDragged) {
    this.sdDragged = sdDragged
  }

  setInvoiceFormDateField(index, value) {
    this.sdToUpdate[index]['extraData'][SupportingDocTypes.INVOICE] = {
      ...this.sdToUpdate[index]['extraData'][SupportingDocTypes.INVOICE],
      date: !value || !moment(value) ? null : value,
    }
  }

  setInvoiceFormFields(field, value, sdId) {
    const index = findIndex(propEq('id', sdId))(this.sdToUpdate)
    if (field === 'date') {
      this.setInvoiceFormDateField(index, value)
    } else {
      this.sdToUpdate[index]['extraData'][SupportingDocTypes.INVOICE] = {
        ...this.sdToUpdate[index]['extraData'][SupportingDocTypes.INVOICE],
        [field]: value,
      }
    }
  }

  setInvoiceError(field, errorMsg) {
    this.invoiceError = this.invoiceError[field]
      ? {
          ...this.invoiceError,
          ...(this.invoiceError[field] = [...this.invoiceError[field], errorMsg]),
        }
      : { ...this.invoiceError, [field]: [errorMsg] }
  }

  invoiceDateValidation(sd) {
    if (sd.extraData[SupportingDocTypes.INVOICE].date === null) {
      this.setInvoiceError('date', 'form.requiredField')
    } else if (!isDateBeforeToday(sd.extraData[SupportingDocTypes.INVOICE].date)) {
      this.setInvoiceError('date', 'form.invoiceDate')
    }
  }

  updateInvoiceFormValidation(sd) {
    // In case of change type in the slider
    this.invoiceError = {}
    Object.keys(toJS(sd.extraData[SupportingDocTypes.INVOICE])).forEach(field => {
      if (field !== 'date' && !isRequiredValid(sd.extraData[SupportingDocTypes.INVOICE][field])) {
        this.setInvoiceError(field, 'form.requiredField')
      }
    })
    this.invoiceDateValidation(sd)
    if (Object.keys(this.invoiceError).length === 0) {
      this.updateSupportingDocument(sd.id, toJS(sd.extraData))
    }
  }

  isSelected(sdId) {
    return this.sdSelected.indexOf(sdId) !== -1
  }

  addCreateMissionSelectedSd(sdId) {
    this.createMissionSelected.push(sdId)
  }

  removeCreateMissionSelectedSd(sdId) {
    const index = this.createMissionSelected.indexOf(sdId)
    if (index !== -1) {
      this.createMissionSelected.splice(index, 1)
    }
  }

  setIsCreateMissionSelector(bool) {
    this.isCreateMissionSelector = bool
  }

  clearCreateMissionSelectedSd() {
    this.createMissionSelected = []
  }

  isCreateMissionSelected(sdId) {
    return this.createMissionSelected.indexOf(sdId) !== -1
  }

  getSdById(id) {
    const index = findIndex(propEq('id', id))(SupportingDocumentStore.supportingDocuments)
    return SupportingDocumentStore.supportingDocuments[index]
  }

  getSdToUpdateById(id) {
    const index = findIndex(propEq('id', id))(this.sdToUpdate)
    return this.sdToUpdate[index]
  }

  resetToDefault() {
    this.modalDisplay = false
    this.modalDeleteDisplay = false
    this.sliderModalDisplay = false
    this.dropZoneFiltered = false
    this.dropZoneFilteredUpdateType = false
    this.isCreateMissionSelector = false
  }
}

const DecoratedSupportingDocumentsCtrl = decorate(SupportingDocumentsCtrl, {
  loading: observable,
  imageLoaded: observable,
  filter: observable,
  modalDisplay: observable,
  sdToUpdate: observable,
  hasChanged: observable,
  updateLoading: observable,
  sdSelectLoading: observable,
  sdSelected: observable,
  sdAddedToReport: observable,
  modalDeleteDisplay: observable,
  selectedSlide: observable,
  sliderModalDisplay: observable,
  dropZoneFiltered: observable,
  dropZoneFilteredUpdateType: observable,
  sdDragged: observable,
  invoiceError: observable,
  createMissionSelected: observable,
  isCreateMissionSelector: observable,
  ctrl: observable,

  notDeletableSelectedSd: computed,
  selectedSd: computed,
  createMissionSelectedSd: computed,
  deletableSelectedSd: computed,
  sdCategory: computed,
  sdCategoryLabel: computed,
  sdCategoryLabelLowerCase: computed,

  setDefaultCtrl: action.bound,
  fetchSupportingDocuments: action,
  setImagesLoaded: action,
  setFilter: action,
  resetDatas: action,
  setName: action,
  setTypeAndName: action,
  updateSupportingDocument: action,
  setDeleteModal: action,
  removeDeleteModal: action,
  deleteSupportingDocument: action,
  addSelectedSd: action,
  addSelectedSdAll: action,
  removeSelectedSd: action,
  setSelectedSlide: action,
  setSliderModalDisplay: action,
  setDropZoneFiltered: action,
  resetSdSelected: action,
  setDropZoneFilteredUpdateType: action,
  setSdDragged: action,
  setInvoiceFormDateField: action,
  setInvoiceFormFields: action,
  setInvoiceError: action,
  invoiceDateValidation: action,
  updateInvoiceFormValidation: action,
  addCreateMissionSelectedSd: action,
  removeCreateMissionSelectedSd: action,
  setIsCreateMissionSelector: action,
  clearCreateMissionSelectedSd: action,
  resetToDefault: action.bound,
})

export default new DecoratedSupportingDocumentsCtrl()
