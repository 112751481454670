import { observable, action, computed, runInAction, decorate } from 'mobx'

import UserStore from 'stores/Common/domain/UserStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { getDataLocal, setDataLocal } from 'stores/localStorage'
import { auth, authSelfCare, requestPassword } from 'services/authentification'
import i18next from 'i18next'

class LoginCtrl {
  customerId = ''
  userName = ''
  password = ''
  loading = false
  cookiesDisclaimer = true
  modalChangePassword = false
  showModalForgottenPassword = false
  forgottenPasswordState = 'available'
  forgottenPasswordCustomerId = ''
  forgottenPasswordEmail = ''
  forgottenPasswordErrors = []

  constructor() {
    getDataLocal('cookiesDisclaimer') === null
      ? setDataLocal('cookiesDisclaimer', true)
      : (this.cookiesDisclaimer = getDataLocal('cookiesDisclaimer'))
    const customerId = getDataLocal('customerId')
    this.customerId = customerId === null ? '' : customerId
  }

  get verifyForm() {
    return this.customerId.length === 11 && this.userName.length > 0 && this.password.length > 0
  }

  setProperty(key, value) {
    this[key] = value
  }

  closeCookiesDisclaimer() {
    this.cookiesDisclaimer = false
    setDataLocal('cookiesDisclaimer', false)
  }

  async authentification() {
    this.loading = true
    try {
      const res = await auth({
        customerId: this.customerId,
        userName: this.userName,
        password: this.password,
      })
      runInAction(() => {
        UserStore.loadUserData({
          token: res.access_token,
          refreshToken: res.refresh_token,
          expiresIn: res.expires_in,
        })
        setDataLocal('customerId', this.customerId)
        this.loading = false
      })
    } catch (err) {
      runInAction(() => {
        if (err && err.error_description) AlertCtrl.alert('danger', `${err.error_description}`)
        this.loading = false
      })
    }
  }

  authentificationSelfCare = async (wan, uuid, trigrams) => {
    this.loading = true
    try {
      const res = await authSelfCare({ wan, uuid, trigrams })

      await UserStore.loadUserDataSelfCare({
        token: res.access_token,
        refreshToken: res.refresh_token,
        expiresIn: res.expires_in,
        selfCareWan: wan,
        selfCareUuid: uuid,
      })

      runInAction(() => {
        this.loading = false
      })
    } catch (err) {
      runInAction(() => {
        AlertCtrl.alert('danger', 'selfCare.login.claimNotFound')
        this.loading = false
      })

      throw err
    }
  }

  async requestPassword() {
    this.forgottenPasswordState = 'pending'

    try {
      await requestPassword({
        customerId: this.forgottenPasswordCustomerId,
        email: this.forgottenPasswordEmail,
      })
      runInAction(() => {
        AlertCtrl.alert('success', i18next.t('loginPage.modal.forgottenPassword.requestSuccess'))
        this.forgottenPasswordState = 'done'
        this.forgottenPasswordErrors = []
        this.forgottenPasswordCustomerId = ''
        this.forgottenPasswordEmail = ''
        this.showModalForgottenPassword = false
      })
    } catch (err) {
      err.globals.forEach(error => {
        AlertCtrl.alert('danger', `${error}`)
      })
      runInAction(() => {
        this.forgottenPasswordState = 'error'
        this.forgottenPasswordErrors = err.fields
      })
    }
  }
}

const DecoratedLoginCtrl = decorate(LoginCtrl, {
  customerId: observable,
  userName: observable,
  password: observable,
  loading: observable,
  cookiesDisclaimer: observable,
  modalChangePassword: observable,
  showModalForgottenPassword: observable,
  forgottenPasswordState: observable,
  forgottenPasswordCustomerId: observable,
  forgottenPasswordEmail: observable,
  forgottenPasswordErrors: observable,

  requestPassword: action,
  authentification: action,
  authentificationSelfCare: action,
  closeCookiesDisclaimer: action,
  setProperty: action,

  verifyForm: computed,
})

export default new DecoratedLoginCtrl()
