import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { path } from 'ramda'
import { useTranslation } from 'react-i18next'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { concatAddress, formatPhone } from 'utils'
import useClickOutside from 'hooks/useClickOutside'

import './Stepper.css'
const Stepper = ({
  SelfCareStore: { loading, claim, stepperPositionFromStatus },
  UserStore: { customer },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const closeModal = () => setShowModal(false)

  let domNode = useClickOutside(closeModal)

  const canShow = position => {
    return (
      position === stepperPositionFromStatus ||
      position + 1 === stepperPositionFromStatus ||
      stepperPositionFromStatus === position - 1
    )
  }

  const isActive = position => {
    return position < stepperPositionFromStatus
  }

  const isCurrent = position => {
    return position === stepperPositionFromStatus
  }

  const onClick = position => {
    if (!isCurrent(position)) return

    setShowModal(true)
  }

  const lastStep = position => {
    return position === 6
  }

  const Step = ({ position, label }) => {
    if (!canShow(position)) return null

    return (
      <li
        className={classNames({
          active: isActive(position),
          current: isCurrent(position),
          lastLi: lastStep(position),
        })}
      >
        <div
          className={classNames(
            { active: isActive(position), current: isCurrent(position) },
            'stepBubble',
          )}
          onClick={() => onClick(position)}
        >
          {isActive(position) && <i className="fa fa-check" aria-hidden="true"></i>}
          {isCurrent(position) && <i className="fa fa-info" aria-hidden="true"></i>}
          {!isActive(position) && !isCurrent(position) && position}
        </div>
        {label}
        <span
          className={classNames(
            { active: isActive(position), lastStep: lastStep(position) },
            'stepLine',
          )}
        ></span>
      </li>
    )
  }

  if (loading) return null

  return (
    <Fragment>
      <footer className="stepper-bar">
        <div className="col-12 text-center p-0">
          <div className="px-0 pt-4 pb-0">
            <ul
              className={classNames('stepper d-flex', {
                'justify-content-center': stepperPositionFromStatus === 99,
              })}
            >
              <Step position={0} label={t('selfCare.stepper.step0')} />
              <Step position={1} label={t('selfCare.stepper.step1')} />
              <Step position={2} label={t('selfCare.stepper.step2')} />
              <Step position={3} label={t('selfCare.stepper.step3')} />
              <Step position={4} label={t('selfCare.stepper.step4')} />
              <Step position={5} label={t('selfCare.stepper.step5')} />
              <Step position={6} label={t('selfCare.stepper.step6')} />
              <Step position={99} label={t('selfCare.stepper.step99')} />
            </ul>
          </div>
        </div>
      </footer>

      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={closeModal}
        draggable
        setGrabbing={setGrabbing}
      >
        <div
          ref={domNode}
          className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}
        >
          <h5 className="modal-title">{t('selfCare.summary.title')}</h5>
          <button
            type="button"
            className="close"
            onClick={closeModal}
            onMouseDown={closeModal}
            onTouchStart={closeModal}
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5 className="modal-title">{t('selfCare.summary.myInformation')}</h5>
          <div className="row no-gutters pl-4">
            <div className="col-12">
              <span className="font-weight-bold">{t('selfCare.summary.fullName')}</span>{' '}
              {path(['insuredInformation', 'insured', 'lastName'], claim)}{' '}
              {path(['insuredInformation', 'insured', 'firstName'], claim)}
            </div>
          </div>

          <div className="row no-gutters pl-4 mt-2">
            <div className="col-12">
              <span className="font-weight-bold">{t('selfCare.summary.email')}</span>{' '}
              {path(['insuredInformation', 'insured', 'email'], claim)} <br />
              <span className="font-weight-bold">{t('selfCare.summary.phone')}</span>{' '}
              {formatPhone(path(['insuredInformation', 'insured', 'mobilePhone'], claim))}
            </div>
          </div>

          <div className="row no-gutters pl-4 mt-2">
            <div className="col-12">
              <span className="font-weight-bold">{t('selfCare.summary.contract')}</span>{' '}
              {path(['contract', 'policyNumber'], claim)} <br />
              <span className="font-weight-bold">{t('selfCare.summary.insuredAddress')}</span>{' '}
              {concatAddress(path(['claimInformation', 'addressOfLoss'], claim))} <br />
              <span className="font-weight-bold">
                {t('selfCare.summary.referenceSinister')}
              </span>{' '}
              {path(['claimInformation', 'insurerClaimId'], claim)}
            </div>
          </div>

          <h5 className="modal-title mt-4">{t('selfCare.summary.insurer')}</h5>
          <div className="row no-gutters pl-4">
            <div className="col-12">{path(['name'], customer)}</div>
          </div>
          <div className="row no-gutters pl-4">
            <div className="col-12">
              <span className="font-weight-bold">{t('selfCare.summary.insurerAddress')}</span>{' '}
              {concatAddress(path(['address'], customer))} <br />
              <span className="font-weight-bold">{t('selfCare.summary.insurerPhone')}</span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
            </div>
          </div>
          <div className="row no-gutters mt-2 pl-4">
            <div className="col-12">
              <span className="font-weight-bold">{t('selfCare.summary.servicePhone')}</span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
              <span className="font-weight-bold">{t('selfCare.summary.assistantPhone')}</span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
            </div>
          </div>
        </div>
      </ModalWithBackdrop>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'UserStore')(observer(Stepper))
