import { action, computed, decorate, observable, runInAction } from 'mobx'
import { isValid, format, addHours, getHours } from 'date-fns'
import { path } from 'ramda'

import SelfCareStore from 'stores/SelfCare/domain/SelfCareStore'
import CartStore from 'stores/Mission/domain/CartStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { backSelfCare, confirmOrientationSelfCare, saveSelfCareForm } from 'services/selfCare'
import { delay } from 'services/utils'

class SelCareIndemnisationCtrl {
  loading = false

  choices = [
    'gagAvailable',
    'mobilierAvailable',
    'renAvailable',
    'ipAvailable',
    'gagMobilierAvailable',
    'renMobilierAvailable',
    'contactInsuredAvailable',
  ]

  gagAvailable = false
  gagAmount = 0

  mobilierAvailable = false
  mobilierAmount = 0

  renAvailable = false
  renAmount = 0

  ipAvailable = false
  ipAmount = 0

  gagMobilierAvailable = false
  gagMobilierAmount = 0
  gagMobilierTravauxAmount = 0
  gagMobilierMobilierAmount = 0

  renMobilierAvailable = false
  renMobilierAmount = 0
  renMobilierTravauxAmount = 0
  renMobilierMobilierAmount = 0

  contactInsuredAvailable = true

  choice = null
  appointmentDate = null
  accepedChoice = false
  showModal = false

  get nbTotalChoices() {
    let nbTotalChoices = 0
    this.choices.forEach(choice => {
      if (this[choice]) nbTotalChoices++
    })

    return nbTotalChoices
  }

  positionOfChoice = choiceName => {
    let positionOfChoice = 0

    for (let i = 0; i < this.choices.length; i++) {
      if (this[this.choices[i]]) positionOfChoice++
      if (choiceName === this.choices[i]) return positionOfChoice
    }

    return positionOfChoice
  }

  get formatAppointmentDate() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'dd/MM/y')
  }

  get appointmentDateHourStart() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'HH:mm')
  }

  get appointmentDateHourEnd() {
    if (!isValid(this.appointmentDate)) return null

    return format(addHours(new Date(this.appointmentDate), 2), 'HH:mm')
  }

  get appointmentDateISO() {
    if (!isValid(this.appointmentDate)) return null

    return format(this.appointmentDate, "yyyy-MM-dd'T'HH:mm:ssxxx")
  }

  get isValid() {
    if (!this.choice) return false
    if (
      ['ren', 'ip', 'contactInsured', 'renMobilier'].includes(this.choice) &&
      (!this.appointmentDate || !isValid(this.appointmentDate))
    )
      return false

    return true
  }

  get isValidWithAccepdChoice() {
    if (!this.isValid) return false
    if (!this.accepedChoice) {
      return false
    }

    return true
  }

  loadData = (claim, orientation) => {
    this.loading = true
    this.gagAvailable = orientation.gagAvailable || false
    this.gagAmount = orientation.gagAmount || 0
    this.mobilierAvailable = orientation.mobilierAvailable || false
    this.mobilierAmount = orientation.mobilierAmount || 0
    this.renAvailable = orientation.renAvailable || false
    this.renAmount = orientation.renAmount || 0
    this.ipAvailable = orientation.ipAvailable || false
    this.ipAmount = orientation.ipAmount || 0
    this.gagMobilierAvailable = orientation.gagMobilierAvailable || false
    this.gagMobilierAmount = orientation.gagMobilierAmount || 0
    this.gagMobilierTravauxAmount = orientation.gagMobilierTravauxAmount || 0
    this.gagMobilierMobilierAmount = orientation.gagMobilierMobilierAmount || 0
    this.renMobilierAvailable = orientation.renMobilierAvailable || false
    this.renMobilierAmount = orientation.renMobilierAmount || 0
    this.renMobilierTravauxAmount = orientation.renMobilierTravauxAmount || 0
    this.renMobilierMobilierAmount = orientation.renMobilierMobilierAmount || 0
    this.contactInsuredAvailable = orientation.contactInsuredAvailable || true

    this.choice = path(['orientationSelfCare', 'choice'], claim)
    this.appointmentDate = path(['orientationSelfCare', 'appointmentDate'], claim)
      ? new Date(path(['orientationSelfCare', 'appointmentDate'], claim))
      : null
    if (SelfCareStore.selfCareStatus === 'select_indemnisation') this.showModal = true
    this.loading = false
  }

  setProperty = (key, value) => {
    this[key] = value
    if (key === 'appointmentDate' && getHours(this.appointmentDate) === 0) {
      this.appointmentDate.setHours(9)
    }
  }

  save = async wan => {
    if (!this.isValid) return

    this.loading = true
    let attributes = {
      choice: this.choice,
      appointmentDate: this.appointmentDateISO,
    }

    try {
      const res = await saveSelfCareForm(wan, attributes, 'selfCareIdemnisation')
      SelfCareStore.updateClaim(res)
      runInAction(() => {
        this.showModal = true
      })
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  backToIndemnisation = async wan => {
    this.loading = true
    try {
      const res = await backSelfCare(wan, 'selfCareSelectIdemnisation')
      SelfCareStore.updateClaim(res)
      runInAction(() => {
        this.accepedChoice = false
        this.showModal = false
      })
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  confirmOrientation = async wan => {
    if (!this.isValidWithAccepdChoice) return

    this.loading = true
    let attributes = {
      choice: this.choice,
      appointmentDate: this.appointmentDateISO,
    }

    try {
      if (this.choice === 'ren' || this.choice === 'renMobilier') {
        CartStore.changeAllPackageTo('ren', true)
      }
      if (this.choice === 'ip') {
        CartStore.changeAllPackageTo('selfRepair', false)
      }
      await delay(1000) // to ensure autorun has been execute
      await CartStore.save()
      const res = await confirmOrientationSelfCare(wan, attributes, 'selfCareIdemnisation')
      SelfCareStore.updateClaim(res)
      runInAction(() => {
        this.showModal = false
      })
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedSelCareIndemnisationCtrl = decorate(SelCareIndemnisationCtrl, {
  loading: observable,

  gagAvailable: observable,
  gagAmount: observable,
  mobilierAvailable: observable,
  mobilierAmount: observable,
  renAvailable: observable,
  renAmount: observable,
  ipAvailable: observable,
  ipAmount: observable,
  gagMobilierAvailable: observable,
  gagMobilierAmount: observable,
  gagMobilierTravauxAmount: observable,
  gagMobilierMobilierAmount: observable,
  renMobilierAvailable: observable,
  renMobilierAmount: observable,
  renMobilierTravauxAmount: observable,
  renMobilierMobilierAmount: observable,
  contactInsuredAvailable: observable,

  choice: observable,
  appointmentDate: observable,
  accepedChoice: observable,
  showModal: observable,

  isValid: computed,
  isValidWithAccepdChoice: computed,
  formatAppointmentDate: computed,
  appointmentDateHourStart: computed,
  appointmentDateHourEnd: computed,
  appointmentDateISO: computed,
  nbTotalChoices: computed,

  loadData: action,
  setProperty: action,
  save: action,
  confirmOrientation: action,
  backToIndemnisation: action,
})

export default new DecoratedSelCareIndemnisationCtrl()
