import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const PercentageDammaged = ({
  PropertyEmbellishmentCtrl: {
    selectedPart,
    percentageDammaged,
    setProperty,
    computeAreaForPackages,
  },
}) => {
  const { t } = useTranslation()

  const walls = [
    { label: 1, value: 25 },
    { label: 2, value: 50 },
    { label: 3, value: 75 },
    { label: 4, value: 100 },
  ]

  const noWalls = [
    { label: '25 %', value: 25 },
    { label: '50 %', value: 50 },
    { label: '75 %', value: 75 },
    { label: '100 %', value: 100 },
  ]

  if (selectedPart === 'C' || selectedPart === 'F')
    return (
      <div>
        <label className="d-block">{t('selfCare.estimation.covering.percentageDamage')}</label>

        <div className="btn-group d-flex">
          {noWalls.map(noWall => (
            <button
              key={`noWall-${noWall.label}`}
              className={classNames('btn flex-basis-1-4', {
                'btn-primary': percentageDammaged === noWall.value,
                'btn-outline-primary': percentageDammaged !== noWall.value,
              })}
              onClick={() => {
                setProperty('percentageDammaged', noWall.value)
                computeAreaForPackages()
              }}
            >
              {noWall.label}
            </button>
          ))}
        </div>
      </div>
    )
  else if (selectedPart === 'W')
    return (
      <div>
        <label className="d-block">{t('selfCare.estimation.covering.numberWalls')}</label>

        <div className="btn-group d-flex">
          {walls.map(wall => (
            <button
              key={`wall-${wall.label}`}
              className={classNames('btn flex-basis-1-4', {
                'btn-primary': percentageDammaged === wall.value,
                'btn-outline-primary': percentageDammaged !== wall.value,
              })}
              onClick={() => {
                setProperty('percentageDammaged', wall.value)
                computeAreaForPackages()
              }}
            >
              {wall.label}
            </button>
          ))}
        </div>
      </div>
    )

  return null
}

export default inject('PropertyEmbellishmentCtrl')(observer(PercentageDammaged))
