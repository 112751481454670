import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader/Loader'
import { euro } from 'utils/currency'
import { formatDateHour } from 'utils'

const SelfCareFinish = ({ SelCareFinishCtrl: { choice, amount, loading, businessEvents } }) => {
  const { t } = useTranslation()
  if (loading) return <Loader />

  if (businessEvents.length === 0)
    return (
      <Fragment>
        {!['abandon', 'noDamage'].includes(choice) && (
          <div className="border border-primary rounded p-3 mb-3">
            <div className="row no-gutters mb-4">
              <div className="col-12">
                <h4>Nous vous remercions d'avoir réalisé votre déclaration de sinistre en ligne</h4>
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-12">Vous avez choisi :</div>
            </div>

            <div className="row no-gutters">
              <div className="col-12 font-weight-bold">
                {choice === 'gag' && t('selfCare.indemnisation.confirmModal.gag')}
                {choice === 'mobilier' && t('selfCare.indemnisation.confirmModal.mobilier')}
                {choice === 'ren' && t('selfCare.indemnisation.confirmModal.ren')}
                {choice === 'ip' && t('selfCare.indemnisation.confirmModal.ip')}
                {choice === 'gagMobilier' && t('selfCare.indemnisation.confirmModal.gagMobilier')}
                {choice === 'renMobilier' && t('selfCare.indemnisation.confirmModal.renMobilier')}
                {choice === 'contactInsured' &&
                  t('selfCare.indemnisation.confirmModal.contactInsured')}
              </div>
            </div>

            {['gag', 'mobilier', 'gagMobilier'].includes(choice) && (
              <div className="row no-gutters mt-4">
                <div className="col-12">
                  {t('selfCare.finish.amount', { amount: `${euro(amount)}` })}
                </div>
              </div>
            )}

            <div className="row no-gutters mt-4">
              <div className="col-12">{t('selfCare.finish.recap')}</div>
            </div>
          </div>
        )}

        {choice === 'abandon' && (
          <div className="border border-primary rounded p-3 mb-3">
            <div className="row no-gutters mb-4">
              <div className="col-12">
                <h4>{t('selfCare.finish.abandon')}</h4>
              </div>
            </div>

            <div className="row no-gutters mt-4">
              <div className="col-12">{t('selfCare.finish.contact')}</div>
            </div>
          </div>
        )}

        {choice === 'noDamage' && (
          <div className="border border-primary rounded p-3 mb-3">
            <div className="row no-gutters mb-4">
              <div className="col-12">
                <h4>{t('selfCare.finish.nodamage')}</h4>
              </div>
            </div>

            <div className="row no-gutters mt-4">
              <div className="col-12">{t('selfCare.finish.contact')}</div>
            </div>
          </div>
        )}
      </Fragment>
    )

  return (
    <Fragment>
      {businessEvents.map((businessEvent, key) => (
        <Fragment>
          <div className="row no-gutters p-3 mt-4 bg-primary text-white font-weight-bold">
            <div className="col-12">
              <h6>
                <i className="fa fa-folder-open-o" aria-hidden="true"></i>{' '}
                {formatDateHour({ date: businessEvent.createdAt })}
              </h6>
            </div>
          </div>

          <div className="row no-gutters p-3">
            <div className="col-12">{businessEvent.labelSelfCare}</div>
          </div>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default inject('SelCareFinishCtrl')(observer(SelfCareFinish))
