import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { setDataLocal } from 'stores/localStorage'
import { inject } from 'mobx-react'
import LoginCtrl from 'stores/Common/view/LoginCtrl'
import InputMask from 'react-input-mask'
import Button from 'components/Button/Button'
import './Login.css'

const LoginSelfCare = ({ UserStore: { logout } }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const { wan, uuid } = useParams()
  const [loading, setLoading] = useState(false)
  const [trigrams, setTrigrams] = useState('')
  const [hasError, setHasError] = useState(false)

  const changeTrigrams = value => {
    setHasError(false)
    setTrigrams(value.replace(/\s/g, '').toUpperCase())
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (trigrams.length >= 2) {
      try {
        setLoading(true)
        await LoginCtrl.authentificationSelfCare(wan, uuid, trigrams)
        history.push(`/sc/${wan}`)
        return
      } catch (err) {
        setLoading(false)
        setHasError(true)
      }
    }
  }

  const verifyForm = () => {
    return trigrams.length >= 2
  }

  useEffect(() => {
    logout(false)
    setDataLocal('selfCareWan', wan)
    setDataLocal('selfCareUuid', uuid)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="login-sc test-reload text-center">
      <div className="border box-shadow-sc p-4">
        <h4 className="mb-4">{t('selfCare.login.title')}</h4>
        <form>
          <InputMask
            className={classNames({ 'is-invalid': hasError }, 'form-control  thrigrams')}
            name="trigrams"
            mask="*         *        *"
            maskChar=""
            required={true}
            alwaysShowMask={false}
            onChange={e => changeTrigrams(e.target.value)}
            value={trigrams}
            autoComplete="off"
          />
          {hasError && <div className="invalid-feedback">{t('selfCare.login.claimNotFound')}</div>}
          <Button
            className="btn btn-primary login-btn btn-block"
            onClick={onSubmit}
            disabled={!verifyForm || loading}
            loading={loading}
          >
            {t('selfCare.login.validate')}
          </Button>
        </form>

        <h6 className="mt-4"> {t('selfCare.login.exampleTitle')}</h6>
        <div>
          Elyas <span className="font-weight-bolder">Dri</span>dba :{' '}
          <span className="font-weight-bolder">DRI</span>
        </div>
        <div>
          Élodie <span className="font-weight-bolder">Fré</span>gé :{' '}
          <span className="font-weight-bolder">FRE</span>
        </div>
        <div>
          Erri <span className="font-weight-bolder">De L</span>uca :{' '}
          <span className="font-weight-bolder">DEL</span>
        </div>
        <div>
          Jean <span className="font-weight-bolder">D'Ai</span>llon :{' '}
          <span className="font-weight-bolder">DAI</span>
        </div>
        <div>
          Chien Cong <span className="font-weight-bolder">Le</span> :{' '}
          <span className="font-weight-bolder">LE</span>
        </div>
      </div>
    </div>
  )
}

export default inject('UserStore')(LoginSelfCare)
