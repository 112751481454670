import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { useTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'
import DatePicker from 'components/Forms/DatePicker'
import InputPrice from 'components/InputPrice/InputPrice'
import Button from 'components/Button/Button'
import Furniture from 'stores/Mission/domain/Furniture'
import Label from 'components/Label/Label'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import Select from 'components/Forms/Select'
import UploadPhoto from 'pages/SelfCare/Estimation/UploadPhoto'

const FurnitureAdd = ({
  furniture,
  onCancel,
  onConfirm,
  mode = 'new',
  CommonStore: { furnitureCategoriesOptions },
  SelfCareStore: { wan },
}) => {
  const { t } = useTranslation()

  // Trick to disable mobile keyboard on datepicker
  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container')
    Array.from(datePickers).forEach(el => el.childNodes[0].setAttribute('readOnly', true))
  })

  const save = () => {
    if (
      !isEmpty(furniture.description) &&
      (!isEmpty(furniture.dateOfPurchased) || furniture.isUnknownDateOfPurchased) &&
      !isEmpty(furniture.category)
    ) {
      checkObs()
      onConfirm()
    }
  }

  const categoriesOptions = () => {
    const options = {}
    furnitureCategoriesOptions.forEach(option => {
      options[option.value] = option.label
    })

    return options
  }
  const checkObs = () => {
    const { dateOfPurchased, category } = furniture
    const obs = AddFurnitureCtrl.checkObsByDateAndCategory(dateOfPurchased, category)
    if (!isNil(obs)) {
      furniture.setProperty('correction', obs)
      furniture.setProperty('obsRateFoundInDatabase', true)
    } else {
      furniture.setProperty('obsRateFoundInDatabase', false)
    }
  }

  return (
    <div className="border border-primary rounded p-3 mb-3">
      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required>{t('mission.calculation.furniture.category')}</Label>
          <Select
            className="form-control"
            name="category"
            value={furniture.category}
            onChange={e => furniture.setProperty('category', e.target.value)}
            options={categoriesOptions()}
            placeholder=" - Selection - "
          />
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required>{t('mission.calculation.furniture.description')}</Label>
          <Input
            name="description"
            className="form-control"
            value={furniture.description}
            required={true}
            onChange={e => furniture.setProperty('description', e.target.value)}
          />
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12 align-items-center">
          <div className="form-check">
            <input
              type="checkbox"
              id="isUnknownDateOfPurchased"
              className="form-check-input"
              name="isUnknownDateOfPurchased"
              checked={furniture.isUnknownDateOfPurchased}
              onChange={e => {
                const value = !furniture.isUnknownDateOfPurchased
                furniture.setProperty('isUnknownDateOfPurchased', value)
                if (value) furniture.setProperty('dateOfPurchased', null)
              }}
            />
            <label htmlFor="isUnknownDateOfPurchased" className="form-check-label">
              {t('mission.calculation.furniture.isUnknownDateOfPurchased')}
            </label>
          </div>
        </div>
      </div>

      {!furniture.isUnknownDateOfPurchased && (
        <div className="row no-gutters">
          <div className="form-group col-12">
            <Label>{t('mission.calculation.furniture.dateOfPurchased2')}</Label>
            <DatePicker
              name="dateOfPurchased"
              className="form-control"
              onChange={date => furniture.setProperty('dateOfPurchased', date)}
              selected={furniture.dateOfPurchased}
              maxDate={new Date()}
              required={false}
              placeholder="dd/mm/yyyy"
              datepickerPlacement="top"
            />
          </div>
        </div>
      )}

      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required>{t('mission.calculation.furniture.purchasedPrice')}</Label>
          <InputPrice
            className="form-control"
            name="priceWithVat"
            price={furniture.totalPriceVAT}
            classNamesForPrice="font-weight-bold text-primary form-control"
            disableShowUnit
            maxLengthNumber={6}
            required={true}
            autoComplete="off"
            onChange={e => furniture.setProperty('priceWithVat', e.target.value)}
          />
        </div>
      </div>

      <div className="mt-3 mb-3">
        <label className="d-block">{t('selfCare.estimation.covering.photos')}</label>
        <div className="d-flex">
          <UploadPhoto
            photoId={furniture.photoDamaged}
            setPhotoId={id => furniture.setProperty('photoDamaged', id)}
            deletePhotoId={() => furniture.setProperty('photoDamaged', null)}
            filename="Photo bien endommagé"
            filenameToUpload="photo-bien-endommage"
            wan={wan}
          />
          <UploadPhoto
            photoId={furniture.photoProofPurchase}
            setPhotoId={id => furniture.setProperty('photoProofPurchase', id)}
            deletePhotoId={() => furniture.setProperty('photoProofPurchase', null)}
            filename="Photo justificatif d'achat"
            filenameToUpload="photo-justificatif-achat"
            wan={wan}
          />
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12 d-flex justify-content-end">
          <Button className="btn btn-secondary" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button className="ml-2 btn btn-primary" onClick={save}>
            {t('common.validate')}
          </Button>
        </div>
      </div>
    </div>
  )
}

FurnitureAdd.prototype = {
  furniture: PropTypes.instanceOf(Furniture),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default inject('CommonStore', 'SelfCareStore')(observer(FurnitureAdd))
