import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { isNil } from 'ramda'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button/Button'
import CoveringForm from 'pages/SelfCare/Estimation/CoveringForm'
import Covering from 'pages/SelfCare/Estimation/Covering'

const CurrentRoom = ({
  showNewForm,
  setShowNewForm,
  CartStore: { packages, save },
  PropertyEmbellishmentCtrl: {
    currentRoom,
    currentRCP,
    editCoveringForm,
    initRoomForm,
    selectedPart,
  },
  SelCareEstimationCtrl: { loading },
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className={classNames('cart', { 'd-none': isNil(currentRoom) })}>
        {currentRCP.length > 0 && (
          <div className="mb-3">
            {currentRCP.map(rcpData => {
              const rcpPackages = packages.filter(data => data.groupId === rcpData.groupId)

              return (
                <Covering
                  key={`covering-${rcpData.groupId}`}
                  rcpData={rcpData}
                  rcpPackages={rcpPackages}
                  packageData={rcpPackages[0]}
                  closeNewForm={() => setShowNewForm(false)}
                  loading={loading}
                />
              )
            })}
          </div>
        )}

        <CoveringForm
          mode="new"
          show={showNewForm}
          closeForm={() => {
            save()
            setShowNewForm(false)
          }}
        />

        {!showNewForm && (
          <Button
            className="btn btn-primary"
            disabled={editCoveringForm !== null || loading}
            onClick={() => {
              initRoomForm()
              setShowNewForm(true)
            }}
          >
            {t('selfCare.estimation.covering.addCovering')}
          </Button>
        )}
      </div>
    </Fragment>
  )
}

export default inject('CartStore', 'PropertyEmbellishmentCtrl', 'SelCareEstimationCtrl')(
  observer(CurrentRoom),
)
