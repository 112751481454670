import { action, computed, decorate, observable, runInAction } from 'mobx'
import { path } from 'ramda'
import SelfCareStore from 'stores/SelfCare/domain/SelfCareStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { backSelfCare, saveSelfCareForm } from 'services/selfCare'
import SelCareWhoCtrl from './SelCareWhoCtrl'

const COVERAGE_CLIMATIC_EVENT = 'CLET'
class SelCareWhatCtrl {
  loading = false
  coverageKey = null
  eventTypeKey = null

  get isValid() {
    return this.coverageKey && this.eventTypeKey
  }

  loadData = claim => {
    this.coverageKey = path(['claimInformation', 'coverageKey'], claim)
    this.eventTypeKey = path(['claimInformation', 'eventTypeKey'], claim)
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  save = async wan => {
    if (!this.isValid) return

    this.loading = true
    let attributes = {
      coverageKey: this.coverageKey,
      eventTypeKey: this.eventTypeKey,
    }
    try {
      const res = await saveSelfCareForm(wan, attributes, 'selfCareWhat')
      if (this.coverageKey === COVERAGE_CLIMATIC_EVENT) {
        await SelCareWhoCtrl.autoSelectOriginEventNature(wan)
      } else {
        SelfCareStore.updateClaim(res)
      }
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  back = async wan => {
    this.loading = true
    try {
      const res = await backSelfCare(wan, 'selfCareWhat')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedSelCareWhatCtrl = decorate(SelCareWhatCtrl, {
  loading: observable,
  coverageKey: observable,
  eventTypeKey: observable,

  isValid: computed,

  loadData: action,
  setProperty: action,
  save: action,
  back: action,
})

export default new DecoratedSelCareWhatCtrl()
