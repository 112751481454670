import { observable, computed, action, runInAction, decorate } from 'mobx'
import i18next from 'i18next'
import { isNil, uniq, path, add, sortBy, clone } from 'ramda'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import Package from 'stores/Mission/domain/Package'
import CartStore from 'stores/Mission/domain/CartStore'
import { fetchRoomCoveringPackages } from 'services/calculation'
import { uid } from 'utils'
import { computeArea } from 'utils/easyEstimation'

class PropertyEmbellishmentCtrl {
  listRooms = []
  selectedRoom = ''
  showRoomModal = false

  // FORM
  selectedPart = ''
  percentageDammaged = 100
  selectedCoveringCde = ''
  fetching = false
  packages = []
  qualityType = 'medium'
  area = null
  originArea = null
  quantity = 1
  editCoveringForm = null
  showCalculator = false
  showObsolescenceCalculator = false
  unitByDefault = ''
  firstTimeWall = false
  photo1 = null
  photo2 = null

  get currentRoom() {
    return this.listRooms.find(room => room.id === this.selectedRoom)
  }

  get currentRCP() {
    if (isNil(this.currentRoom)) return []
    return CartStore.rcp.filter(data => data.roomId === this.currentRoom.id)
  }

  get currentParts() {
    if (isNil(this.currentRoom)) return []

    return sortBy(part => ['F', 'W', 'C', 'V', 'O'].indexOf(part.id))(
      uniq(this.currentRoom.coverings.map(covering => covering.covering.part)).map(part => ({
        id: part,
        label: i18next.t(`mission.easyEstimation.parts.${part}`),
      })),
    )
  }

  get selectedCovering() {
    if (this.selectedPart.length === 0) return []
    else if (isNil(path(['coverings'], this.currentRoom))) return []

    return this.currentRoom.coverings.filter(
      covering => covering.covering.part === this.selectedPart,
    )
  }

  get items() {
    const items = []

    this.packages.forEach(packageData => {
      packageData.items.forEach(item => {
        if (!item.disabledChoice && item.materials.length > 1) {
          items.push(item)
        }
      })
    })
    return items
  }

  get isSquareMeter() {
    return this.selectedPart === 'C' || this.selectedPart === 'W' || this.selectedPart === 'F'
  }

  get totalCovering() {
    return this.packages.reduce((acc, data) => add(acc, data.finalPrice), 0)
  }

  get totalCoveringPerUnity() {
    if (this.isSquareMeter) return this.totalCovering / this.area

    return this.totalCovering / this.quantity
  }

  fetchRoomCovering = async (wan, coveringCde) => {
    this.selectedCoveringCde = coveringCde
    this.fetching = true

    try {
      const room = this.listRooms.find(data => data.id === this.selectedRoom)
      if (isNil(room)) {
        throw new Error('Room is undefined')
      }

      const res = await fetchRoomCoveringPackages({
        wan,
        covering: this.selectedCoveringCde,
        room: room.cde,
      })
      runInAction(() => {
        const groupId = uid(10)
        const newUnitByDefault = path(['unitByDefault'], res) || ''

        if (this.unitByDefault !== newUnitByDefault) {
          this.unitByDefault = newUnitByDefault
          this.quantity = 1
        }

        this.packages = res.packages.map(
          packageData =>
            new Package({
              data: {
                ...packageData,
                qualityType: this.qualityType,
                quantity: this.quantity,
                location: 'regular',
                // TO FIX W = Wall for example
                localisation: this.selectedPart,
                // DEFAULT TO SELF REPAIR
                selfRepair: true,
                // IF SELF REPAIR IS TRUE REN IS FALSE
                ren: false,
                easyEstimationData: {
                  areaPercentageType: packageData.areaPercentageType,
                  areaPercentagePercentage: packageData.areaPercentagePercentage,
                },
              },
              fromList: 'listSearch',
              packageType:
                packageData.damageTypes.length > 1
                  ? 'DAT_PROP'
                  : path(['damageTypes', 0], packageData),
              roomType: {
                id: this.currentRoom.id,
                key: this.currentRoom.cde,
                label: this.currentRoom.label,
                areaSize: this.area,
              },
              items: packageData.items.map(item => clone(item)),
              priceType: 'catalog',
              groupId,
            }),
        )
        this.computeAreaForPackages()
        this.showCalculator = false
      })
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  createRoomFromCart = packages => {
    const rooms = []
    packages.forEach(data => {
      const existingRoom = rooms.find(room => data.roomType.id === room.id)

      if (isNil(existingRoom)) {
        const roomData = SearchPackageCtrl.missionRooms.find(
          roomData => roomData.cde === data.roomType.key,
        )
        if (roomData) {
          rooms.push({
            ...roomData,
            id: data.roomType.id,
            label: data.roomType.label,
          })
        }
      }
    })
    this.listRooms = rooms
    if (this.listRooms.length > 0) this.selectedRoom = this.listRooms[0].id
  }

  createRoomFromSelfCare = selectedRooms => {
    selectedRooms.forEach(cde => {
      const existingRooms = this.listRooms.find(room => room.cde === cde)
      if (isNil(existingRooms)) {
        const roomData = SearchPackageCtrl.missionRooms.find(roomData => roomData.cde === cde)

        if (roomData) {
          this.listRooms.push({
            ...roomData,
            id: uid(10),
          })
        }
      }
    })

    if (this.listRooms.length > 0 && !this.selectedRoom) this.selectedRoom = this.listRooms[0].id
  }

  addNewRoom = room => {
    const existingRooms = this.listRooms.filter(roomData => roomData.cde === room.cde)
    const label =
      existingRooms.length > 0 ? `${room.label} ${existingRooms.length + 1}` : room.label
    const newRoom = {
      ...room,
      id: uid(10),
      label,
    }
    this.listRooms.push(newRoom)
    this.selectedRoom = newRoom.id
    this.area = parseFloat(newRoom.areaSize)
    this.originArea = parseFloat(newRoom.areaSize)
    this.quantity = 1
    this.firstTimeWall = true
  }

  deleteRoom = roomId => {
    this.listRooms = this.listRooms.filter(data => data.id !== roomId)
    CartStore.deleteByRoom(roomId)
    this.selectedRoom = ''
  }

  initRoomForm = () => {
    this.area = path([0, 'area'], this.currentRCP) || this.currentRoom.areaSize
    this.originArea = this.area
    this.quantity = 1
    this.firstTimeWall = true
  }

  resetCoveringForm = () => {
    this.selectedPart = ''
    this.percentageDammaged = 100
    this.selectedCoveringCde = ''
    this.packages = []
    this.showCalculator = false
    this.unitByDefault = ''
    this.photo1 = null
    this.photo2 = null
  }

  changePackagesProperty = (packageKey, value) => {
    this.packages.forEach(packageData => packageData.setProperty(packageKey, value))
  }

  addCovering = mode => {
    if (mode === 'edit') {
      this.deleteCovering(this.editCoveringForm)
    }

    if (this.area !== this.originArea) {
      this.changeAllRCP()
    }

    const packageData = this.packages[0]

    const rcpData = {
      groupId: packageData.groupId,
      part: this.selectedPart,
      percentageDammaged: this.percentageDammaged,
      area: this.area,
      roomLabel: this.currentRoom.label,
      roomselfCareLabel: this.currentRoom.hasOwnProperty('selfCareLabel')
        ? this.currentRoom.selfCareLabel
        : this.currentRoom.label,
      quantity: this.quantity,
      qualityType: this.qualityType,
      covering: {
        cde: this.selectedCoveringCde,
        label: this.selectedCovering.find(data => data.covering.cde === this.selectedCoveringCde)
          .covering.label,
      },
      roomId: this.selectedRoom,
      unitByDefault: this.unitByDefault,
      indemMode: packageData.selfRepair ? 'GAG' : packageData.ren === true ? 'REN' : 'IP',
      photo1: this.photo1,
      photo2: this.photo2,
    }

    CartStore.addRCP({
      id: uid(10),
      ...rcpData,
    })
    this.packages.forEach(packageData => CartStore.addPackage(packageData))
    this.resetCoveringForm()
  }

  editCovering = (rcpData, rcpPackages) => {
    this.selectedPart = rcpData.part
    this.percentageDammaged = rcpData.percentageDammaged
    this.area = rcpData.area
    this.originArea = rcpData.area
    this.quantity = rcpData.quantity
    this.qualityType = rcpData.qualityType
    this.selectedCoveringCde = rcpData.covering.cde
    this.packages = rcpPackages
    this.editCoveringForm = rcpData.groupId
    this.unitByDefault = rcpData.unitByDefault
    this.photo1 = rcpData.photo1 || null
    this.photo2 = rcpData.photo2 || null
  }

  deleteCovering = groupId => {
    CartStore.deleteRCP(groupId)
  }

  emptyRooms = () => {
    this.listRooms = []
    this.selectedRoom = ''
  }

  computeAreaForPackages = () => {
    this.packages.forEach(packageData => {
      computeArea(
        packageData,
        this.currentRoom,
        this.percentageDammaged,
        this.area,
        this.selectedPart,
      )
    })
  }

  findRcpByGroupId = groupId => {
    let foundRcp = {}
    this.currentRCP.forEach(rcp => {
      if (rcp.groupId === groupId) {
        foundRcp = rcp
      }
    })

    return foundRcp
  }

  changeAllRCP = () => {
    this.currentRCP.forEach(rcp => {
      rcp.area = this.area
    })

    const currentRoomPackages = CartStore.packages.filter(
      data => data.roomType.id === this.currentRoom.id,
    )

    currentRoomPackages.forEach(packageData => {
      const rcp = this.findRcpByGroupId(packageData.groupId)
      if (rcp.part !== 'V' && rcp.selectedPart !== 'O')
        computeArea(packageData, this.currentRoom, rcp.percentageDammaged, rcp.area, rcp.part)
    })

    this.packages.forEach(packageData => {
      computeArea(
        packageData,
        this.currentRoom,
        this.percentageDammaged,
        this.area,
        this.selectedPart,
      )
    })
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  changeArea = val => {
    this.area = val
    // this.changeAllRCP()
  }
}

const DecoratedPropertyEmbellishmentCtrl = decorate(PropertyEmbellishmentCtrl, {
  listRooms: observable,
  selectedRoom: observable,
  showRoomModal: observable,
  photo1: observable,
  photo2: observable,

  currentRoom: computed,
  currentRCP: computed,

  addNewRoom: action,
  resetCoveringForm: action,
  setProperty: action,
  emptyRooms: action,
  deleteRoom: action,
  createRoomFromSelfCare: action,

  // FORM
  selectedPart: observable,
  selectedCoveringCde: observable,
  percentageDammaged: observable,
  fetching: observable,
  currentParts: computed,
  selectedCovering: computed,
  fetchRoomCovering: action,
  packages: observable,
  items: computed,
  qualityType: observable,
  changePackagesProperty: action,
  isSquareMeter: computed,
  area: observable,
  originArea: observable,
  quantity: observable,
  addCovering: action,
  editCovering: action,
  changeArea: action,
  deleteCovering: action,
  totalCovering: computed,
  totalCoveringPerUnity: computed,
  initRoomForm: action,
  editCoveringForm: observable,
  showCalculator: observable,
  showObsolescenceCalculator: observable,
  computeAreaForPackages: action,
  unitByDefault: observable,
  changeAllRCP: action,
  firstTimeWall: observable,
})

export default new DecoratedPropertyEmbellishmentCtrl()
