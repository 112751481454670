import { action, computed, decorate, observable, runInAction } from 'mobx'
import SelfCareStore from 'stores/SelfCare/domain/SelfCareStore'
import { pauseSelfCare, abandonSelfCare } from 'services/selfCare'
import { isValid, format, addHours, getHours } from 'date-fns'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

class SelCareWaitAbandonCtrl {
  loading = false

  waitChoices = ['reflect', 'supportingDocument', 'later', 'abandon']
  waitChoice = null
  appointmentDate = null

  get formatAppointmentDate() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'dd/MM/y')
  }

  get appointmentDateHourStart() {
    if (!isValid(this.appointmentDate)) return null
    return format(this.appointmentDate, 'HH:mm')
  }

  get appointmentDateHourEnd() {
    if (!isValid(this.appointmentDate)) return null

    return format(addHours(new Date(this.appointmentDate), 2), 'HH:mm')
  }

  get appointmentDateISO() {
    if (!isValid(this.appointmentDate)) return null

    return format(this.appointmentDate, "yyyy-MM-dd'T'HH:mm:ssxxx")
  }

  setProperty = (key, value) => {
    this[key] = value
    if (key === 'appointmentDate' && getHours(this.appointmentDate) === 0) {
      this.appointmentDate.setHours(9)
    }
  }

  pause = async wan => {
    this.loading = true
    try {
      await pauseSelfCare(wan, this.waitChoice)
      AlertCtrl.alert('success', 'selfCare.pause.success')
    } catch (err) {
      AlertCtrl.alert('danger', 'selfCare.pause.error')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  abandon = async wan => {
    if (!this.isValid) return
    this.loading = true
    let attributes = {
      appointmentDate: this.appointmentDateISO,
    }

    try {
      const res = await abandonSelfCare(wan, attributes)
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get isValid() {
    return isValid(this.appointmentDate)
  }

  get isValidWait() {
    return this.waitChoice
  }
}

const DecoratedSelCareWaitAbandonCtrl = decorate(SelCareWaitAbandonCtrl, {
  loading: observable,

  appointmentDate: observable,
  waitChoice: observable,

  formatAppointmentDate: computed,
  appointmentDateHourStart: computed,
  appointmentDateHourEnd: computed,
  appointmentDateISO: computed,
  isValid: computed,
  isValidWait: computed,

  setProperty: action,
  pause: action,
  abandon: action,
})

export default new DecoratedSelCareWaitAbandonCtrl()
