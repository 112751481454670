import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import DropDown from 'pages/SelfCare/Estimation/Dropdown'
import CurrentRoom from 'pages/SelfCare/Estimation/CurrentRoom'
import Furnitures from 'pages/SelfCare/Estimation/Furnitures'
import RelatedCosts from 'pages/SelfCare/Estimation/RelatedCosts'
import OverViewModal from 'pages/SelfCare/Estimation/OverViewModal'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Button from 'components/Button/Button'

const SelfCareEstimation = ({
  SelfCareStore: { wan },
  SelCareEstimationCtrl: { isValid, loading, back },
  PropertyEmbellishmentCtrl: { setProperty, listRooms, selectedRoom },
}) => {
  const { t } = useTranslation()

  const [showNewForm, setShowNewForm] = useState(false)
  const [showOverView, setShowOverView] = useState(false)

  return (
    <Fragment>
      <div className="row no-gutters mb-4">
        <div className="col-12">
          <h4>{t('selfCare.estimation.title')}</h4>
        </div>
      </div>

      <DropDown title={t('selfCare.estimation.property')} showDropdown={true}>
        <div className="row no-gutters d-flex justify-content-between align-items-center">
          <div className="col-12">
            <ul className="nav nav-tabs">
              {listRooms.map(({ cde, selfCareLabel, id }) => (
                <li
                  className="nav-item"
                  key={`room-${cde}`}
                  onClick={() => setProperty('selectedRoom', id)}
                >
                  <span
                    className={classNames('nav-link clickable py-2', {
                      active: selectedRoom === id,
                    })}
                  >
                    <span>{selfCareLabel}</span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <CurrentRoom showNewForm={showNewForm} setShowNewForm={setShowNewForm} />
      </DropDown>

      <DropDown title={t('selfCare.estimation.furniture.title')} showDropdown={true}>
        <Furnitures />
      </DropDown>

      <DropDown title={t('selfCare.estimation.relatedCost.title')} showDropdown={true}>
        <RelatedCosts />
      </DropDown>

      <div className="row no-gutters mt-4 d-flex justify-content-between">
        {isValid && (
          <ButtonWithConfirmation
            className="btn btn-secondary"
            text={t('selfCare.common.next')}
            onClick={() => back(wan)}
            disabled={loading}
            loading={loading}
            confirmText={t('selfCare.estimation.backConfirmation')}
          />
        )}

        {!isValid && (
          <Button
            className="btn btn-secondary"
            onClick={() => back(wan)}
            disabled={loading}
            loading={loading}
          >
            {t('selfCare.common.back')}
          </Button>
        )}

        <Button
          className="btn btn-primary"
          onClick={() => setShowOverView(true)}
          disabled={!isValid || loading}
          loading={loading}
        >
          {t('selfCare.common.next')}
        </Button>
      </div>
      <OverViewModal showModal={showOverView} setShowModal={setShowOverView} />
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareEstimationCtrl', 'PropertyEmbellishmentCtrl')(
  observer(SelfCareEstimation),
)
